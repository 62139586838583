import styled from 'styled-components';

export const PauseButton = styled.div`
    border-radius: 50%;
    background-color: white;
    width: clamp(30px, 3vw, 40px);
    height: clamp(30px, 3vw, 40px);

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1023px) {
        width: 4vw;
        height: 4vw;
    }

    @media screen and (max-width: 766px) {
        width: 10vw;
        height: 10vw;
    }
    
`;