import React, { useEffect, useRef, useState } from 'react';

import { Image, Typography, Video, Button, Accordion, List, ListItem, TitlePage, TextToSpeech, Link } from '../../components';

import { Borstvoedingszorg, Inleiding, Waarom, Background, BlogEnPodcast, Sponsoring, Circle } from './styled';
import { Ervaringen, Medewerking, Benieuwd } from '../../subpages';

import waarom from '../../assets/images/home/waarom.jpg';
import sponsoring from '../../assets/images/home/sponsoring.png';
import illustratiesponsoring from '../../assets/images/home/illustratie-sponsoring.png';

import background1 from '../../assets/backgrounds/home1.png';
import background2 from '../../assets/backgrounds/home2.png';

import { GeneralContext } from '../../App';
import { ModalPositieveEffecten, ModalWetenschappelijk, ModalProfessionals, ModalVoorbereiding } from './Popups';
import { Paragraph } from '../../components/Typography/styled';

export default function Home(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ openModalOne, setOpenModalOne ] = useState(false);
    const [ openModalTwo, setOpenModalTwo ] = useState(false);
    const [ openModalThree, setOpenModalThree ] = useState(false);
    const [ openModalVoorbereiding, setOpenModalVoorbereiding ] = useState(false);

    const [ backgroundHeight, setBackgroundHeight ] = useState(null);
    const [ imageHeight, setImageHeight ] = useState(null);
    const [ benieuwdBackgroundHeight, setBenieuwdBackgroundHeight ] = useState(null);

    const waaromContRef = useRef(null);
    const sponsoringRef = useRef(null);
    const BlogEnPodcastRef = useRef(null);

    useEffect(() => {
        if (!backgroundHeight) {
            setBackgroundHeight((borstvoedingRef.current?.clientHeight + inleidingRef.current?.clientHeight)*1.1);
        }
        
        if (!imageHeight) {
            // we manually compute the height because flex already compensates for it automatically
            let boxChildNodes = waaromContRef.current.childNodes;
            let firstChildTop = boxChildNodes[0].getBoundingClientRect().top;
            let lastChildTop = boxChildNodes[boxChildNodes.length - 1].getBoundingClientRect().top;
            setImageHeight(lastChildTop - firstChildTop);
        }

        if (!benieuwdBackgroundHeight) {
            setBenieuwdBackgroundHeight(sponsoringRef.current?.clientHeight + BlogEnPodcastRef.current?.clientHeight + benieuwdRef.current?.clientHeight);
        }

    }, [backgroundHeight, imageHeight, benieuwdBackgroundHeight])

    const pageText = [
        { type: 'title', text: [`Succesvolle\n`, 'borstvoeding'] },
        { type: 'paragraph', text: [`begeleiding voor `, <Link key={0} to='/ouders' style={{ textDecoration: 'underline' }}>ouders</Link>, `, \n`, <Link key={1} to='/lactatiekundigen' style={{ textDecoration: 'underline' }}>lactatiekundigen</Link>, ` en\n`, <Link key={2} to='/verloskundigen' style={{ textDecoration: 'underline' }}>verloskundigen</Link>]},
        { type: 'title', text: ['Goed Aangelegd'] },
        { type: 'paragraph', text: [`Uiteenlopende adviezen, tijdsgebrek bij zorgverleners en brandjes blussen. Deze uitdagingen dragen niet bij aan wat we werkelijk willen: een probleemloze borstvoedingsperiode!`] },
        { type: 'paragraph', text: [`Goed Aangelegd brengt hier verandering in door het verbinding van ouders, lactatiekundigen en verloskundigen. Ontdek wat wij jou te bieden hebben en maak van elk borstvoedingsavontuur een succes!`]},
        { type: 'title', text: ['Waarom Goed Aangelegd?'] },
        { type: 'subtitle', text: ['Borstvoeding, een medicijn voor het leven'] },
        { type: 'paragraph', text: [`Borstvoeding is een intieme handeling tussen moeder en kind `, <span key={0} onClick={() => setOpenModalOne(true)} style={{ textDecoration: 'underline', cursor: 'pointer'}}>met talloze positieve effecten</span>, '. Het heeft zowel fysiek als emotioneel een positieve impact op de gezondheid van moeder en kind. Borstvoeding draagt bijvoorbeeld bij aan een veilige hechting, waar het kind zijn hele leven profijt van heeft. Het is dus veel meer dan alleen voeding.']},
        { type: 'subtitle', text: ['Huidige zorg innoveren: preventie, geen brandjes blussen']},
        { type: 'paragraph', text: ['Borstvoedingsbegeleiding krijgt binnen de verloskundige zorg vaak onvoldoende aandacht door gebrek aan tijd en expertise. De hoge werkdruk en het gebrek aan gespecialiseerde kennis over borstvoeding in de verloskundeopleidingen spelen hierbij een rol. Een borstvoedingsexpert (lactatiekundige) biedt uitkomst, maar wordt vaak pas ingeschakeld als er al borstvoedingsproblemen zijn.']},
        { type: 'subtitle', text: ['Onnodige problemen voorkomen met persoonlijke zorg'] },
        { type: 'paragraph', text: [<span key={0} onClick={() => setOpenModalVoorbereiding(true)} style={{ textDecoration: 'underline', cursor: 'pointer'}}>Een goede voorbereiding op de borstvoedingsperiode kan veel problemen voorkomen</span>, `. De periode na een bevalling is intens. Veel nieuwe indrukken, korte nachten en een lichaam dat aan het herstellen is. In deze turbulente tijd kost het extra energie om zonder voorkennis voor een baby te zorgen. Door tijdens de zwangerschap al kennis en vaardigheden op te doen over het voeden van je kindje, weet je wat je te wachten staat en wat je moet doen. De workshops van Goed Aangelegd helpen je hierbij. `, 'Ze zijn ', <span key={1} onClick={() => setOpenModalTwo(true)} style={{ textDecoration: 'underline', cursor: 'pointer'}}>gebaseerd op wetenschappelijk onderzoek</span>, ' en ', <span key={2} onClick={() => setOpenModalThree(true)} style={{ textDecoration: 'underline', cursor: 'pointer'}}>samengesteld door meer dan 50 professionals.</span>]},
        { type: 'subtitle', text: ['Samenwerken in borstvoedingszorg']},
        { type: 'paragraph', text: ['Onze visie is duidelijk: door nauwe samenwerking tussen lactatiekundigen en verloskundigen benutten we elkaars expertise optimaal en zorgen we voor een fijne en succesvolle borstvoedingsperiode voor zoveel mogelijk (aanstaande) gezinnen.']},
        { type: 'subtitle', text: ['Goed Aangelegd']},
        { type: 'list', text: ['Biedt wetenschappelijk onderbouwde workshops aan via borstvoedingsexperts;', 'Maakt leren leuk en interactief;', 'Brengt (aanstaande) ouders samen in live sessies;', 'Begeleidt ouders al tijdens de zwangerschap;', 'Geeft de partner een centrale rol bij de borstvoeding;', 'Biedt aanvullende digitale ondersteuning;', 'Biedt persoonlijke hulp, ook na het vertrek van de kraamverzorgster en verloskundige.']},
        { type: 'paragraph', text: ['Wil je meer weten? Klik op de groep waartoe jij behoort!']},
    ]

    const waaromRef = useRef(null);

    const borstvoedingRef = useRef(null);
    const inleidingRef = useRef(null);
    const benieuwdRef = useRef(null);

    const buttonList = [
        { name: 'ouder', phoneName: 'ouder', pluralName: 'ouders', link: '/ouders' },
        { name: 'lactatiekundige', phoneName: 'lactatie-kundige', pluralName: 'lactatiekundigen', link: '/lactatiekundigen' },
        { name: 'verloskundige', phoneName: 'verlos-kundige', pluralName: 'verloskundigen', link: '/verloskundigen' }
    ]
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', position: 'relative', overflow: 'hidden' }}>
            {!hasPhone && 
                <Background src={background1} height={hasTablet ? backgroundHeight * 1.3 : backgroundHeight} top={hasTablet ? '-10%' : '0'} right={hasTablet ? '-180px' : '-100px'} />}
            <Borstvoedingszorg ref={borstvoedingRef}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: hasPhone ? '5vw' : '1vw' }}>
                    <TextToSpeech textList={pageText} />
                    <Typography type='h1' style={{ color: '#032d34', whiteSpace: 'pre' }}>{pageText[0].text}</Typography>
                    <Typography type='h2' style={{ color: '#145f68', fontSize: hasPhone ? '1.5em' : hasTablet ? '1.3em' : 'clamp(20px, 1.5em, 50px)', marginBottom: hasPhone ? '5vw' : '2vw', whiteSpace: 'pre' }}>{pageText[1].text}</Typography>
                </div>
                <div style={{ maxWidth: hasPhone ? '100vw' : '90vw', zIndex: 3}}>
                    <Video videoId='RDqENFc32aA' />
                </div>
            </Borstvoedingszorg>
            <Inleiding ref={inleidingRef}>
                <TitlePage 
                    color='#92330f'
                    text='Goed Aangelegd'
                />
                {!hasPhone &&  <div style={{ overflow: 'hidden', width: hasTablet ? '80vw' : 'min-content', height: 'min-content', position: 'absolute', top: '0', left: hasTablet ? '-50%' : '0'}}>
                    <Background src={background2} position='relative' zIndex='0' height={hasTablet ? inleidingRef.current?.clientHeight*0.8 : inleidingRef.current?.clientHeight*0.6 || '0'} />
                </div>}
                <Typography type='h2' style={{ color: '#92330f', fontSize: hasPhone ? '2.5em' : hasTablet ? '2.5em' : '3em', zIndex: '2' }}>{pageText[2].text}</Typography>
                <Typography style={{ fontSize: !hasPhone && '1.2em', width: hasPhone ? '85%' : hasTablet ? '60%' : '50%', margin: hasPhone ? '5vw 0 0 0' : hasTablet ? '3vw' : '3vw', lineHeight: '1.38', zIndex: '2', textAlign: 'left'}}>
                    <span>
                        {pageText[3].text}
                    </span><br/><br/>
                    <span>
                        {pageText[4].text}
                    </span><br/><br/>
                </Typography>
                <div style={{ display: 'flex', gap: hasPhone ? '2vw' : hasTablet ? '4vw' : '5vw', zIndex: '2', flexWrap: 'wrap', justifyContent: hasPhone && 'center', margin: hasTablet && '2vw'}}>
                    {buttonList.map(v => (
                        <Button 
                            key={v.name}
                            circle={true} 
                            width={hasPhone ? '30vw' : hasTablet ? '20vw' : '12vw'}
                            height={hasPhone ? '30vw' : hasTablet ? '20vw' : '12vw'}
                            buttonstyle='sixthly'
                            to={v.link}
                        >
                            <span>
                                <Paragraph style={{ textTransform: 'uppercase', fontSize: hasPhone ? '1em' : '1.2em', color: '#fff'}}>Ik ben een</Paragraph>
                                <Paragraph style={{ textTransform: 'uppercase', fontFamily: 'Amelie', fontSize: hasPhone ? '1em' : '1.5em', color: '#fff'}}>{hasPhone ? v.phoneName : v.name}</Paragraph>
                            </span>
                        </Button>
                    ))}
                </div>
            </Inleiding>
            <Waarom>
                {!hasTablet && <TitlePage 
                    color='#92330f'
                    text='Waarom Goed Aangelegd'
                />}
                <div ref={waaromContRef} style={{ display: 'flex', flexDirection: 'column', gap: hasPhone ? '5vw' : '0.5vw', width: hasPhone ? '90%' : hasTablet ? '60%' : 'clamp(550px, 45%, 700px)'}}>
                    <Typography type='h2' style={{ color: '#032d34', textAlign: (hasPhone || hasTablet) ? 'center': 'left', fontSize: hasPhone ? '2.2em' : hasTablet ? '2.5em' : 'clamp(30px, 3em, 40px)', marginBottom: hasPhone ? '5vw' : '1vw' }}>{pageText[5].text}</Typography>
                    <Typography style={{ width: '100%' }}>
                        <Accordion
                            headertext={pageText[6].text}
                            headercolor='#145f68'
                            align={'flex-start'}
                        >
                            <span>
                                {pageText[7].text}
                            </span>
                        </Accordion>
                        <Accordion
                            headertext={pageText[8].text}
                            headercolor='#145f68'
                            align={'flex-start'}
                        >
                            <span>
                                {pageText[9].text}
                            </span>
                        </Accordion>
                        <Accordion
                            headertext={pageText[10].text}
                            headercolor='#145f68'
                            align={'flex-start'}
                        >
                            <span>
                                {pageText[11].text}
                            </span><br/>
                            <Typography style={{ margin: '1vw 0 0.5vw 0', fontWeight: 'bold'}}>
                                {pageText[12].text}
                            </Typography>
                            <span>
                                {pageText[13].text}
                            </span>
                        </Accordion>
                    </Typography>
                    <Typography style={{ margin: '1vw 0 0.5vw 0', fontWeight: 'bold'}}>
                        {pageText[14].text}
                    </Typography>
                    <List>
                        {pageText[15].text.map((item, i) => (
                            <ListItem key={i}>{item}</ListItem>
                        ))}
                    </List>
                    <Typography type='caps' style={{ color: '#145f68', margin: '1vw 0 0.5vw 0'}}>
                        {pageText[16].text}
                    </Typography> 
                    <div style={{ display: 'flex', gap: hasPhone ? '3vw' : '1vw', flexWrap: 'wrap'}}>
                        {buttonList.map((b, i) => (
                            <Button 
                                key={i}
                                width={hasPhone ? '40vw' : '10vw'}
                                buttonstyle='thirdly'
                                to={b.link}
                                text={b.pluralName}
                            />
                        ))}
                    </div>
                </div>
                {imageHeight && <Image src={waarom} height={hasPhone ? '80vw' : (imageHeight || '20vw')} width={hasPhone ? '85vw' : hasTablet ? '20vw' : '25vw'} objectFit={'cover'} />}
            </Waarom>
            <Ervaringen />
            <Medewerking />
            <Sponsoring ref={sponsoringRef}>
                <TitlePage 
                    color='#92330f'
                    text='Sponsoring'
                />
                <Image src={illustratiesponsoring} width={hasPhone ? '40vw' : hasTablet ? '22vw' : '18vw'} height={hasPhone ? '50vw' : hasTablet ? '25vw' : '20vw'} style={{ border: 'none', zIndex: hasPhone ? '1' : '3', position: hasPhone ? 'absolute' : 'relative', bottom: '0', left: '0', opacity: hasPhone && 0.2 }} />
                <div style={{ display: 'flex', flexDirection: 'column', padding: hasPhone ? '10vw 15vw' : '5vw 0 2vw 0' }}>
                    <Typography type='h2' style={{ color: '#b04f29', fontSize: hasPhone ? '2.1em' : '40px', margin: hasPhone ? '0 0 5vw 0' : '0 0 1.5vw 0' }}>Wij steunen het goede doel</Typography>
                    <div style={{ width: hasPhone? '100%' : '425px', position: 'relative', zIndex: hasPhone ? '3' : '1' }}>
                        <Typography>Goed Aangelegd sponsort op structurele basis het project Mothers On the Move. Voor elke workshopsreeks die start, kan er een dame veilig bevallen in Kenia.</Typography>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: hasPhone ? '2vw' : '5vw', margin: hasPhone ? '5vw 0 0 0' : '1vw 0 0 0' }}>
                            <Circle>
                                <Image src={sponsoring} width='60px' height='50px' style={{ objectFit: 'fill', border: 'none' }} />
                            </Circle>
                            <Button 
                                text='Lees hier meer over wat deze stichting doet'
                                to='/MOM'
                                width={hasPhone ? '250px' : '200px'}
                                buttonstyle='seventhly'
                            />
                        </div>
                    </div>
                </div>
            </Sponsoring>
            <BlogEnPodcast ref={BlogEnPodcastRef}>
                <TitlePage 
                    color='#92330f'
                    text='Podcast'
                />
                <Typography type='h2' style={{ color: '#92330f', fontSize: hasPhone || hasTablet ? '2.5em' : '45px', margin: hasPhone ? '0 0 5vw 0' : '0 0 1vw 0' }}>Podcast</Typography>
                <Typography style={{ width: hasPhone ? '80%' : '500px', margin: hasPhone && '5vw 0', textAlign: 'left'}}>
                    <span>Ben je een aanstaande ouder? Ontdek snel onze podcast met praktische tips over borstvoeding en eerlijke voedingsverhalen.</span><br/><br/>
                    <span>Ben jij een lactatiekundige of verloskundige? Maak kennis met de filosofie van Goed Aangelegd via onze podcast voor professionals.</span>
                </Typography>
                <Button 
                    buttonstyle='secondary'
                    to='/podcast'
                    text='Yes, ik ben benieuwd'
                    style={{ margin: '1vw 0 0 0'}}
                />
            </BlogEnPodcast>
            <Benieuwd givenRef={benieuwdRef} fullBackgroundHeight={benieuwdBackgroundHeight} />
            <ModalPositieveEffecten
                open={openModalOne}
                onClose={() => setOpenModalOne(false)}
            />
            <ModalVoorbereiding 
                open={openModalVoorbereiding}
                onClose={() => setOpenModalVoorbereiding(false)}
            />
            <ModalWetenschappelijk
                open={openModalTwo}
                onClose={() => setOpenModalTwo(false)}
            />
            <ModalProfessionals 
                open={openModalThree}
                onClose={() => setOpenModalThree(false)}
            />
        </div>
    );
}