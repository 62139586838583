import React from 'react';
import Link from './Link';

import { Container } from './styled';

export default function BoldLink(props) {
    if (props.onClick) {
        return (
            <Container onClick={props.onClick} {...props}>
                {props.text}
            </Container>
        )
    }
    
    return (
        <Container>
            <Link to={props.to}>{props.text}</Link>
        </Container>
    )
}