import React from 'react';
import { StyledIFrame } from './styled';

export default function Video(props) {
    return (
        // <StyledVideo controls type="video/mp4" disablePictureInPicture controlsList="noplaybackrate nodownload">
        //     <source src="https://www.youtube.com/watch?v=RDqENFc32aA" type='video/mp4' />
        // </StyledVideo>
        <StyledIFrame src={`https://www.youtube.com/embed/${props.videoId}?modestbranding=1&rel=0`}
            allow='autoplay; encrypted-media; fullscreen;'
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            title='video'
            width={props.width}
            height={props.height}
        />
    )
}