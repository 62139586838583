import styled from "styled-components";

export const SingleContainer = styled.div`
    display: flex;
    gap: 2vw;
    margin: 1vw 0;

    align-items: center;
    justify-content: center;

    position: relative;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        gap: 3vw;
    }
`; 

export const SingleBox = styled.div`
    padding: 3vw;
    background-color: #f7f3ec;
    position: relative;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 6vw;
    }
`;

export const SingleArrow = styled.div`
    font-family: Amelie;
    font-size: 4em;
    color: #d08f66;
    z-index: 2;

    :hover {
        cursor: pointer;
        font-weight: 600;
        color: #93b6ae;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        font-size: 4em;
    }
`;

export const StyledTransition = styled.div`
    height: 250px;
    width: 50vw;
    max-width: 700px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: opacity 400ms ease-in-out;
    opacity: ${props => props.noOpacity ? '0' : '1'};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        width: 70vw;
        height: 300px;
        font-size: 12px;
    }

    @media screen and (max-width: 768px) {
        height: 320px;
    }
`;

export const TripleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    z-index: 3;
    width: 80%;
    gap: 2vw;
    margin: 2vw 0;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        width: 95%;
        gap: 1vw;
    }
`;

export const TripleArrow = styled.div`
    font-family: Amelie;
    font-size: 4em;
    color: ${props => props.contentType === 'medewerking' ? '#145f68' : '#92330f'};
    height: min-content;
    margin-top: 60px;
    z-index: 5;

    :hover {
        cursor: pointer;
        font-weight: 600;
        color: ${props => props.contentType === 'medewerking' ? '#93B6AE' : '#92330f'};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        margin-top: 5vw;
        font-size: 5em;
    }

    @media screen and (max-width: 770px) {
        font-size: 3em;
    }
`

export const TripleMainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5vw;

    text-align: center;

    opacity: ${props => props.visible ? '1' : '0'};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        width: 40vw;
        gap: 2vw;
        justify-content: flex-start;
    }
`

export const TripleBox = styled.div`
    display: flex;
    gap: 3vw;

    cursor: pointer;

    position: relative;
    z-index: 4;

    text-decoration: none;

    transition: opacity 500ms ease-out;
    opacity: ${props => !props.visiblebox ? '0' : '1'};
    // z-index: ${props => !props.visiblebox ? '-1' : '0'};
    position: ${props => !props.visiblebox && !props.transitioning ? 'absolute' : 'relative'};
    
    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 770px) {
        cursor: default;
    }
`;

export const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3vw;
    z-index: 0;

    background-color: white;
    width: 470px;
    max-height: 180px;
    font-size: 14px;

    padding: 20px;

    transition: opacity 300ms ease-in;
    opacity: ${props => props.showInfo ? '1' : '0'};
    position: ${props => props.showInfo ? 'relative' : 'absolute'};
    z-index: ${props => props.showInfo ? '-1' : '-2'};
    // display: ${props => props.showInfo ? 'default' : 'none'};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 770px) {
        width: 350px;
    }
`

export const CustomA = styled.a`
    text-decoration: none;
    color: inherit;

    :hover {
        font-weight: 600;
    }
`;

export const CirclePhoto = styled.img`
    width: 5vw;
    height: 5vw;
    clip-path: circle();
    object-fit: cover;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        width: 15vw;
        height: 15vw;
    }

    @media screen and (max-width: 768px) {
        width: 8vw;
        height: 8vw;
    }
`;