import React from "react";

import { RoundIcon } from './styled';

import { AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';
import { FaItunesNote } from "react-icons/fa";
import { FaLinkedinIn } from 'react-icons/fa';
import { FaSpotify } from "react-icons/fa";



import { GeneralContext } from "../../App";

export default function ReactIcon(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const getIcon = (name) => {
        switch(name) {
            case 'instagram':
                return <AiOutlineInstagram 
                    onClick={() => !props.noLink && window.open('https://www.instagram.com/goed.aangelegd/', '_blank')}
                    size={hasPhone ? '6vw' : hasTablet ? '2.5vw' : '1.5vw'}
                    style={{ fill: 'white', margin: 'auto', stroke: 'none'}} 
                />
            case 'linkedin':
                return <FaLinkedinIn 
                    onClick={() => !props.noLink && window.open('https://www.linkedin.com/company/goed-aangelegd/', '_blank')}
                    size={hasPhone ? '5vw' : hasTablet ? '2vw' : '1.2vw'}
                    style={{ fill: 'white', margin: 'auto', stroke: 'none'}} 
                />
            case 'youtube':
                return <AiOutlineYoutube 
                    onClick={() => !props.noLink && window.open('https://www.youtube.com/@goedaangelegd', '_blank')}
                    size={hasPhone ? '6vw' : hasTablet ? '2vw' : '1.5vw'}
                    style={{ fill: 'white', margin: 'auto', stroke: 'none'}} 
                />
            case 'spotify':
                return <FaSpotify 
                    size={hasPhone ? '5vw' : hasTablet ? '2vw' : '1.2vw'}
                    style={{ fill: 'white', margin: 'auto', stroke: 'none'}} 
                />
            case 'itunes':
                return <FaItunesNote 
                    size={hasPhone ? '5vw' : hasTablet ? '2vw' : '1.2vw'}
                    style={{ fill: 'white', margin: 'auto', stroke: 'none'}} 
                />
            default:
                return null;
        }
    }

    if (props.name.length > 0) {
        return (
            <RoundIcon circleFill={props.circleFill} noHover={props.noHover}>
                {getIcon(props.name)}
            </RoundIcon>
        )
    }

    return null;
}