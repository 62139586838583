export function titleCase(string){
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function mod(n, m) {
    return ((n % m) + m) % m;
}

export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

export function range(start, stop, step) {
  step = step || 1;
  var arr = [];
  for (var i=start;i<stop;i+=step){
     arr.push(i);
  }
  return arr;
};