import React, { useState, useEffect } from 'react';
import { Image, Typography } from '..';
import { TripleBox, TripleMainContent, InfoBox, CustomA } from './styled';
import { Link } from 'react-router-dom';
import { GeneralContext } from '../../App';

export default function TripleCarouselComponent(props) {
    const { item, currIndex, visible, updateVisibilityItems, imagePath, contentType, showInfo } = props;
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const MAX_LENGTH_QUOTE = 250;

    let imageSize = '200px';
    if (hasTablet) {
        imageSize = '150px';
    } else if (hasPhone) {
        imageSize = '110px';
    }

    const showBox = (showStatus, currIndex) => {
        const newStatus = !showStatus;
        // setShowInfo(newStatus);

        updateVisibilityItems(newStatus, currIndex);
    }

    const splitQuote = (quote) => {
        let newQuote = quote;
        if (quote.length > MAX_LENGTH_QUOTE) {
            newQuote = newQuote.substring(0, MAX_LENGTH_QUOTE);
            newQuote += '...'
        }
        
        return newQuote;
    }

    if (!visible) {
        return <div style={{ position: 'absolute' }} />;
    }

    if (contentType === 'medewerking' && visible != null) {
        return (
            <TripleBox visiblebox={visible.toString()} onClick={() => !hasPhone && showBox(showInfo, currIndex) }>
                <TripleMainContent visible={visible}>
                    <Image objectFit='cover' style={{ width: imageSize, height: imageSize, transition: 'opacity 500ms ease-in-out', opacity: !props.visible ? '0' : '1' }} src={item.image} />
                    <Typography type='h2' style={{ fontSize: hasPhone ? '1em' : hasTablet ? '1.2em' : '1.3em' }}>{item['voornaam'] + ' ' + item['achternaam']}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: hasPhone ? '8px' : hasTablet ? '12px' : '16px', margin: '0.5vw 0 0 0', maxWidth: imageSize }}>
                        <Typography style={{ fontSize: hasPhone ? '12px' : hasTablet ? '14px' : '16px' }}>{item['functie1']}</Typography>
                        <Typography style={{ fontSize: hasPhone ? '12px' : hasTablet ? '14px' : '16px'}}>{item['functie2']}</Typography>
                    </div>
                </TripleMainContent>
                {showInfo && <InfoBox showInfo={showInfo}>
                    <Typography><span style={{ fontWeight: '600'}}>Naam: </span>{item.voornaam + ' ' + item['achternaam']}</Typography>
                    <div>
                        <Typography style={{ display: 'inline'}}><span style={{ fontWeight: '600'}}>Functie: </span>{item['functie1']}</Typography> 
                        {item['functie2'].length > 0 && <Typography style={{ display: 'inline'}}>{' | ' + item['functie2']}</Typography>}
                    </div>
                    <Typography><span style={{ fontWeight: '600'}}>Praktijk: </span>{item['bedrijf']}</Typography>
                    <Typography><span style={{ fontWeight: '600'}}>Website: </span>
                        <CustomA href={`https://${item.website}`} target="_blank">{item['website']}</CustomA>
                    </Typography>
                    <Typography style={{ margin: '0.5vw 0'}}><span style={{ fontWeight: '600'}}>Bijgedragen aan: </span>{item['bijdrage']}</Typography>
                    {item['quote'].length > 1 && 
                        <Typography style={{ height: imageSize, fontStyle: 'italic', textAlign: 'left', fontSize: '12px', color: '#145f68', overflow: 'hidden' }}>
                            "{splitQuote(item['quote'])}"
                        </Typography>
                    }
                </InfoBox>}
            </TripleBox>
        )
    }

    return <div>Contenttype niet gevonden</div>;
}