import styled from "styled-components";

export const StyledImage = styled.img`
    border: 10px solid ${props => props.borderColor || 'white'};

    width: ${props => props.width || 'fit-content'};
    height: ${props => props.height || '28vw'};

    object-fit: ${props => props.objectFit || 'fill'};
    overflow-clip-margin: unset;s

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        height: ${props => props.height || 'auto'};
        width: ${props => props.width || 'fit-content'};
    }

    @media screen and (max-width: 1023px) {
        height: ${props => props.height || 'auto'};
        width: ${props => props.width || 'fit-content'};
    }
`;