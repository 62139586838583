import styled from 'styled-components';

export const Box = styled.button`
    display: flex;
    text-decoration: none;
    justify-content: ${props => props.align || 'center'};
    align-items: center;
    flex-wrap: wrap;
    gap: ${props => props.noText ? '0' : '5px'};
    padding: ${props => props.circle ? 'auto' : '7px 7px 3px 7px'};
    border: 1px solid ${props => props.backgrcolor || 'white'};
    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};
    white-space: pre-line;
    opacity: ${props => props.disabled ? '0.5' : '1'};

    min-width: ${props => props.minWidth || (props.circle ? '200px' : 'min-content')};
    min-height: ${props => props.circle ? '200px' : 'default'};

    border-radius: ${props => props.circle ? '50%' : '0'};

    background-color: ${props => props.backgrcolor || 'white'};
    color: ${props => props.color || 'black'};

    &:hover {
        cursor: ${props => props.disabled ? 'default' : 'pointer'};
        color: ${props => props.hovercolor || 'red'};
        background-color: ${props => props.hoverbackgrcolor || 'blue'};
        border: 1px solid ${props => props.hoverbackgrcolor || 'blue'};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 5px;
        min-width: inherit;
        min-height: inherit;

        &:hover {
            background-color: ${props => props.backgrcolor || 'white'};
            color: ${props => props.color || 'black'};
        }
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        padding: 5px;

        &:hover {
            background-color: ${props => props.backgrcolor || 'white'};
            color: ${props => props.color || 'black'};
        }
    }
`;

export const Text = styled.div`
    font-size: 14px;
    text-transform: uppercase;
    font-family: Gayathri;
    font-weight: 500;
    line-height: 1.5em;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 14px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 1.1em;
    }
`;

export const CircleText = styled.div`
    font-size: clamp(22px, 2em, 25px);
    font-family: Amelie;
    
    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 1.7em;
    }
`

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
    text-align: center;

    border-radius: 50%;

    font-family: Amelie;
    font-size: ${props => props.fontSize || '14px'};
    color: var(--warmwhite);
    background-color: var(--expectation);

    width: ${props => props.help ? '50px' : '85px'};
    height: ${props => props.help ? '50px' : '85px'};

    cursor: pointer;

    :hover {
        background-color: var(--believe);
        color: var(--courage);
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: ${props => props.help ? '50px' : '85px'};
        height: ${props => props.help ? '50px' : '85px'};
        padding: 1vw;
        font-size: 12px;

        :hover {
            color: var(--warmwhite);
            background-color: var(--expectation);
        }
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        width: ${props => props.help ? '50px' : '85px'};
        height: ${props => props.help ? '50px' : '85px'};
        padding: 1vw;

        :hover {
            color: var(--warmwhite);
            background-color: var(--expectation);
        }
    }
`;

export const CircleContainer = styled.div`
    position: fixed;
    bottom: ${props => props.bottom || '80px'};
    right: ${props => props.right || '10px'};
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;

    height: 250px;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        height: 200px;
        bottom: 5%;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        height: 250px;
        bottom: 5%;
    }

`;