import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5vw;
    user-select: none; /* Standard syntax */

    @media screen and (max-width: 766px) {
        gap: 5vw;
    }
`;

export const SingleArrow = styled.div`
    font-family: Amelie;
    font-size: 90px;
    color: #d08f66;
    z-index: 3;
    user-select: none; /* Standard syntax */

    :hover {
        cursor: pointer;
        font-weight: 600;
        color: #93b6ae;
    }

    @media screen and (max-width: 766px){
        font-size: 80px;
    }
`;