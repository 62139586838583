import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    background-color: #e9f1ed;
    z-index: 3;
    padding: 5vw 0vw;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        gap: 5vw;
        width: 100%;
        text-align: center;
    }
`;