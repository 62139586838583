import styled from "styled-components";

export const Container = styled.div`
    margin: 0.75vw 0;

    color: var(--trust);
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;

    &:hover {
        font-weight: 600;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 3.5vw;
        margin: 2vw 0;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 2vw;
        margin: 1vw 0;
    }
`;

export const Circle = styled.div`
    position: absolute;
    top: 5vw;
    right: 37vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    max-width: 10vw;
    height: 10vw;
    background-color: var(--humus);
    color: var(--warmwhite);
    padding: 0.5vw;

    font-family: Amelie;
    font-size: 1vw;
    text-align: center;

    &:hover {
        background-color: var(--expectation);
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        top: auto;
        bottom: -32vw;
        right: 30vw;
        max-width: 18vw;
        height: 18vw;
        font-size: 2.7vw;
        padding: 3vw 3vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        top: auto;
        bottom: -25vw;
        right: 30vw;
        max-width: 12vw;
        height: 12vw;
        font-size: 1.8vw;
        padding: 3vw 3vw;
    }
`;