import styled from 'styled-components';

export const Rights = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        flex: 0 0 100%;
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        flex: 0 0 100%;
    }
`; 

export const Bar = styled.div`
    z-index: 1;
    background-color: var(--warmwhite);
    padding: 10px 0;
    height: min-content;

    font-size: 15px;
    color: var(--stone);
    text-transform: uppercase;
    line-height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 3vw 0;
    }

    @media screen and (max-width: 1023px) {
        padding: 2vw 0;
    }
`;

export const Subbar = styled(Bar)`
    flex: 0 0 clamp(33%, 33%, 5px);
    gap: clamp(5px, 2vw, 30px);

    display: flex;
    justify-content: center;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 3vw 0 0 0;
        width: 80%;
        flex: none;
        column-gap: 5vw;
        row-gap: 1.5vw;
        font-size: 0.7em;
        flex-wrap: wrap;
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        gap: 2vw;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10vw;

    background-color: var(--believe);
    color: #114d55;
    padding: 3vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 10vw 0;
    }

    @media screen and (max-width: 1023px ) {
        padding: 5vw 0;
        gap: 5vw;
    }
`;

export const InstaImage = styled.img`
    width: 8vw;
    height: 8vw;
    height: default;

    :hover {
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 30vw;
        height: 30vw;
    }
`;