import styled from "styled-components";

export const StyledList = styled.ul`
    margin: ${props => props.margin || '0'};
    padding: 0 1vw; 
    width: ${props => props.width || 'auto'}; 
    
    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        text-align: left;
        margin-left: ${props => props.margin || '5vw'};
        width: ${props => props.width || 'auto'};
    }
`;

export const StyledItem = styled.li`
    padding: 0 0 0.5vw 0.5vw;
    list-style-type: "- ";

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 0 0 2vw 2vw;
        font-size: 14px;
    }
`