import React, { useState, useEffect } from 'react';
import { Introduction } from './styled';
import { Typography, Icon, ReactIcon, Button, Image, SearchField } from '../../components';
import { GeneralContext } from '../../App';
import * as Papa from 'papaparse';
import { Link } from 'react-router-dom';

const loadImages = require.context('../../assets/images/podcast', true);

export default function PodcastOuders(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ episodes, setEpisodes ] = useState([]);
    const [ filteredEpisodes, setFilteredEpisodes ] = useState([]);

    const parsePodcastCsv = (data, columns) => {
        let dataRows = data.data;

        let newEpisodes = []
        for (let i = 1; i < dataRows.length; i++) {
            let row = dataRows[i];
            let dataObj = {}
            for (let j = 0; j < row.length; j++) {
                dataObj[columns[j]] = row[j];
            }
            newEpisodes.push(dataObj);
        }

        newEpisodes = newEpisodes.filter(e => e.type === 'ouders')

        setEpisodes(newEpisodes);
        setFilteredEpisodes(newEpisodes);
    }

    useEffect(() => {
        if (episodes.length === 0) {
            fetch('../podcast.csv')
                .then( response => response.text() )
                .then( responseText => {
                    const data = Papa.parse(responseText);
                    
                    const columns = data['data'][0];
                    parsePodcastCsv(data, columns);
                })
        }
    }, [episodes]);

    return (
        <div>
            <Introduction>
                <Typography type='h1' style={{ color: '#92320F', margin: '0 0 2vw 0' }}>Podcast</Typography>
                <Typography style={{ width: hasPhone ? '80%' : '50%' }}>De podcast Borstvoedingsbabbel van Goed Aangelegd
                    geeft je in 25 afleveringen antwoord op de meest
                    gestelde borstvoedingsvragen tijdens de zwangerschap.
                    Door middel van praktische tips en persoonlijke verhalen
                    bieden we je een eerste voorbereiding op het voeden van
                    jullie kindje. Elke podcast heeft een eigen video en blog.
                </Typography>
                <div style={{ display: 'flex', gap: '5vw', margin: '3vw 0 0 0' }}>
                    <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => window.open(`https://${episodes[0]['algemeenspotify']}`, '_blank')}>
                        <Icon 
                            name='luidspreker' 
                            fill='white'
                            circleFill='#145F68'
                            hoverCircleFill='red'
                            width={hasPhone ? '8vw' : '2vw'}
                        />
                        <Typography style={{ margin: '0.5vw 0 0 0'}}>BELUISTER ZE HIER</Typography>
                    </div>
                    <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => window.open(`https://${episodes[0]['algemeenyoutube']}`, '_blank')}>
                        <ReactIcon name='youtube' circleFill='#145F68' noHover={true} noLink={true}/>
                        <Typography style={{ margin: '0.5vw 0 0 0'}}>BEKIJK ZE HIER</Typography>
                    </div>
                </div>
            </Introduction>
            <div style={{ display: 'flex', padding: '5vw 0', textAlign: 'center', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Typography type='h2' style={{ color: '#ECB991' }}>Borstvoedingsbabbel voor de ouders</Typography>
                <div style={{ margin: hasPhone ? '7vw 0' : hasTablet ? '5vw 0' : '2vw 0'}}>
                    <Typography>Ben je op zoek naar een specifiek onderwerp?</Typography>
                    <Typography>Gebruik dan deze zoekbalk:</Typography>
                    <SearchField 
                        placeholder='zoeken in de podcasts'
                        items={episodes}
                        property='naam'
                        parentFunc={(newEpisodes) => { setFilteredEpisodes(newEpisodes) }}
                    />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', padding: hasPhone ? '0 5vw' : hasTablet ? '0 12vw' : '0 13vw', columnGap: hasPhone ? '13vw' : hasTablet ? '7vw' : '5vw', rowGap: hasPhone ? '5vw' : hasTablet ? '3vw' : '2vw' }}>
                    {filteredEpisodes.length > 0 && filteredEpisodes.map((e, i) => {
                        return (
                            <Link style={{ maxWidth: hasPhone ? '35vw' : '20vw', cursor: 'pointer', textDecoration: 'none' }} to={`/podcast/ouders/${e.nummer}`} key={e.nummer} >
                                <Image src={loadImages(`./Podcastvisual${e.nummer}.png`)} style={{ width: hasPhone ? '35vw' : '20vw', height: hasPhone ? '35vw' : '20vw' }} />
                                <Typography type='h2' style={{ margin: '1vw 0 1vw 0', fontSize: hasPhone ? '1em' : hasTablet ? '1.3em' : '1.3em', color: '#BE6842'}}>
                                    #{e.nummer} {e.naam}
                                </Typography>
                            </Link>
                        )
                    })}
                </div>
            </div>
            <div style={{ display: 'flex', backgroundColor: 'white', flexDirection: 'column', alignItems: 'center', padding: '5vw 0'}}>
                <Typography type='h2' style={{ color: '#BB6848', margin: hasPhone ? '0 0 5vw 0' : '0 0 2vw 0', maxWidth: '80vw' }}>Wil jij een praktische samenvatting van alle afleveringen?</Typography>
                <Typography type='caps' style={{ color: '#B2522D', margin: hasPhone ? '0 0 5vw 0' : '0 0 2vw 0', maxWidth: '80vw' }}>DOWNLOAD HIER GRATIS DE SUCCESVOLLE BORSTVOEDINGSCHECKLIST</Typography>
                <Button 
                    onClick={() => window.open('https://mailchi.mp/f555bfdde75b/gratis-checklist-succesvolle-borstvoeding', '_blank')}
                    text='YES, DEZE WIL IK!'
                />
            </div>
        </div>
    )
}