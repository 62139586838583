import React from 'react';

import { Typography, List, ListItem, Modal, Button } from '../../components';

export const ModalPositieveEffecten = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            top='10vw'
            width='clamp(600px, 50vw, 50vw)'
            left='24vw'
            height='clamp(500px, 50vh, 600px)'
        >
            <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Vind hier de onderbouwing van deze stelling.</div>
            <Typography>Borstvoeding biedt talrijke voordelen, waaronder:</Typography>
            <List>
                <ListItem>Bescherming tegen infecties en ziekten.</ListItem>
                <ListItem>Levering van de juiste voedingsstoffen in de juiste hoeveelheid aan de baby.</ListItem>
                <ListItem>Het bevorderen van een hechtere band tussen moeder en baby, vanwege de productie van het knuffelhormoon Oxytocine.</ListItem>
            </List>
            <Typography>Het niet geven van borstvoeding vergroot de kans op bepaalde infecties, zoals aan het maag- en darmkanaal (overtuigend bewezen), astma, benauwdheid, overgewicht (waarschijnlijk), darmziekten, suikerziekte, jeugdkanker, lage intelligentie (mogelijk).</Typography>
            <Typography>Voor moeders biedt borstvoeding ook gezondheidsvoordelen, zoals een verminderde kans op suikerziekte, reuma en een hoge bloeddruk. Het kan ook de kans op borst-, eierstok- en baarmoederkanker vóór de menopauze verminderen en het risico op osteoporose verlagen. Een bijkomend voordeel voor moeders is dat ze sneller hun gewicht van vóór de zwangerschap kunnen bereiken.</Typography>
            <Typography>Bron: Rijksinstituut voor Volksgezondheid en Milieu. (2015). Health effects of breastfeeding.</Typography>
        </Modal>
    )
}

export const ModalVoorbereiding = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            top='10vw'
            width='clamp(600px, 50vw, 50vw)'
            left='24vw'
            height='clamp(500px, 50vh, 600px)'
        >
            <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Vind hier de onderbouwing van deze stelling.</div>
            <Typography>Het massaal stoppen van borstvoeding in de eerste weken postpartum geeft de grote behoefte aan van borstvoedingshulp, aldus K. Wamback en J. Riordan. Ze beschrijven de meerwaarde van prenatale educatie als volgt: “De meeste moeders maken hun beslissing tussen borst- of kunstvoeding vóór of tijdens hun zwangerschap. De zwangerschap is daarom een geschikte periode om moeders te stimuleren om te kiezen voor borstvoeding, om onjuiste aannames te corrigeren en om de benodigde kennis en vaardigheden over borstvoeding te delen. Het verschil tussen borst- en kunstvoeding moet idealiter besproken worden vóór het voelen van de eerste kindsbewegingen (week 26), omdat de meeste ouders dan een knoop gaan doorhakken over de voedingskeuze.”</Typography>
            <Typography>De Multidisciplinaire Richtlijn Borstvoeding (2015) beschrijft: méér vrouwen geven langer borstvoeding met de juiste voorbereiding op de lactatieperiode. Hieruit volgt de volgende aanbeveling. “Onderzoek laat zien dat begeleiding bij de voorbereiding op de borstvoedingsperiode door zorgverleners effect kan hebben op de start en duur van de borstvoeding. Een brede aanpak op beleidsniveau, met gecoördineerde pre- en postnatale begeleiding en ondersteuning vanuit borstvoedingsorganisaties, blijkt het meest effectief te zijn. Onderzoek concludeert dat interactieve en gevarieerde benaderingen zoals contact met ervaringsdeskundigen, huisbezoek en het volgen van een borstvoedingscursus het meest effectief zijn.”</Typography>
            <Typography>Ten slotte heeft prenatale borstvoedingsbegeleiding het voordeel dat de aanstaande ouders al tijdens de zwangerschap de lactatiekundige leren kennen. Hierdoor wordt de drempel verlaagd om postnataal tijdig een hulpvraag neer te leggen bij de lactatiekundige.</Typography>
            <Typography>Bron:<br/>
                    Wamback, K., Riordan, J. (2016). Breastfeeding and Human Lactation (pp. 875-882) (5e editie). Jones & Barlett.<br/>
                    Kamphuis, M., Schönbeck , Y. (2015). Multidisciplinaire richtlijn borstvoeding. Nederlands Centrum Jeugdgezondheidszorg (NCJ); TNO. Geraadpleegd op 29 juli 2023, van https://repository.tno.nl/islandora/object/uuid%3A6b284929-39fc-4fe3-a3b0-36cbe74caee0.
            </Typography>
        </Modal>
    )
}


export const ModalWetenschappelijk = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            top='10vw'
            width='clamp(600px, 50vw, 50vw)'
            left='24vw'
            height='clamp(500px, 50vh, 600px)'
        >
            <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Vind hier de onderbouwing van deze stelling.</div>
            <Typography>Goed Aangelegd is gebaseerd op het literatuuronderzoek van K. Wamback en J. Riordan. Uit hun onderzoek blijkt dat (aanstaande) ouders in elke fase van de zwangerschap behoefte hebben aan specifieke informatie over borstvoeding. Deze inzichten vormen de structuur van de workshops.
                De aanpak van de dienstverlening is eveneens gebaseerd op onderzoek, waarbij interactieve en gevarieerde benaderingen als meest effectief naar voren komen. De dienstverlening wordt continu geëvalueerd om de kwaliteit en effectiviteit te verbeteren.</Typography>
            <Typography>Bron:<br/>
                Wamback, K., Riordan, J. (2016). Breastfeeding and Human Lactation (pp. 875-882) (5e editie). Jones & Barlett.<br/>
                Hannula, L., Kaunonen, M., Tarkka, M.T. (2008). A systematic review of professional support interventions for breastfeeding. J Clin Nurs. 17(9):1132-1143.
            </Typography>
        </Modal>
    )
}

export const ModalProfessionals = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            top='10vw'
            width='clamp(600px, 50vw, 50vw)'
            left='24vw'
            height='clamp(500px, 50vh, 600px)'
        >
            <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Vind hier de onderbouwing van deze stelling.</div>
            <Typography>In samenwerking met onderwijskundigen, grafisch vormgevers en meer dan 50 andere (zorg)professionals hebben we de borstvoedingsworkshops ontwikkeld. Dankzij deze diverse groep experts hebben we een stevig en kwalitatief fundament voor onze dienstverlening kunnen creëren. De zorgprofessionals hebben de workshops voorzien van de meest recente adviezen binnen hun vakgebied. Ons team van designers heeft de dienstverlening in een moderne, universele en ecologische vorm gegoten.</Typography>
            {false && <Button to='/medewerking' text='Bekijk hier het team' />}
        </Modal>
    )
}

