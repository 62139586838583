import styled from "styled-components";

export const Vragen = styled.div`
    padding: 5vw 0;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    flex-wrap: wrap;
    gap: 10vw;

    background-color: #fdfcfa;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 10vw 0;
        gap: 5vw;
    }
`;