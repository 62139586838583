import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      // scroll to the top
      window.scrollTo(0, 0);
      // turn off the text to speech
      window.speechSynthesis.cancel();
    }, [pathname]);
  
    return null;
}

export const scrollIntoView = (ref) => {
  ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const scrollToPos = (ref, yOffset) => {


  const y = ref.current?.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({top: y, behavior: 'smooth' });
}