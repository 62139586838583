import React from 'react';

import { AiOutlineClose } from "react-icons/ai";
import { Modal as ModalMUI } from '@mui/material';
import { Typography } from '..';

import { ModalBox } from './styled';
import { GeneralContext } from '../../App';

export default function Modal(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    return (
        <ModalMUI
            open={props.open}
            onClose={props.onClose}
        >
            <ModalBox onSubmit={props.onSubmit} top={props.top} width={props.width} left={props.left} height={props.height}>   
                <div onClick={props.onClose} style={{ position: 'absolute', cursor: 'pointer', width: '100%', height: '5em', top: '0', right: '0', zIndex: '2' }}>
                    <AiOutlineClose style={{ position: 'absolute', right: 'clamp(10px, 0.2vw, 20px)', top: 'clamp(10px, 0.2vw, 20px)', cursor: 'pointer', zIndex: '2'}} size={hasPhone ? '7vw' : 'clamp(20px, 1.5vw, 30px)'} />
                </div>
                <Typography type='h2' style={{ fontSize: hasPhone ? '2.5em' : '2em', color: '#032d34', marginBottom: hasPhone ? '5vw' : '1vw', zIndex: '0'}}>{props.title}</Typography>
                {props.children}
            </ModalBox>
        </ModalMUI>
    )
}