import React, { useRef, useEffect, useState } from 'react';
import { Typography } from '..';
import { GeneralContext } from '../../App';

export default function TitlePage(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const DEFAULT_WIDTH = '30px';

    const elementRef = useRef();

    const [ offsetWidth, setOffsetWidth ] = useState(null);

    useEffect(() => {
        if (!offsetWidth) {
            elementRef.current ? setOffsetWidth(`calc(${DEFAULT_WIDTH} - ${elementRef.current.offsetWidth / 2}px)`) : setOffsetWidth('0');
        }
    }, [elementRef])

    if (hasPhone) {
        return null;
    }

    return (
        <div
            ref={elementRef}
            style={{
                position: 'absolute',
                transformOrigin: 'center center',
                transform: 'rotate(-90deg)',
                top: '50%',
                left: offsetWidth,
                zIndex: 5,
            }}
        >
            <Typography type='caps' style={{ color: props.color, fontSize: '16px', fontWeight: '600' }}>{props.text}</Typography>
        </div>
    )
}