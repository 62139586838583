import styled from "styled-components";

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.widthStyle || 'default'};
    height: ${props => props.heightStyle || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};

    object-fit: cover;
`;  

export const Container = styled.div`
    width: 100%;
    background-color: white;

    padding: 5vw 0vw;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 15vw 0;
    }
`;
