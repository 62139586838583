import styled from "styled-components";

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5vw;
    padding: 5vw 0;

    width: 100%;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        min-height: inherit;
        gap: 4vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        gap: 3vw;
    }
`; 

export const Circle = styled.div`
    background-color: var(--warmwhite);
    border-radius: 50%;
    width: 20vw;
    height: 20vw;

    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 60vw;
        height: 60vw;
        margin: 0 0 7vw 0;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        width: 35vw;
        height: 35vw;
    }
`;