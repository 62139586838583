import styled from "styled-components";

export const StyledIFrame = styled.iframe`
    border: 1vw solid white;
    position: relative;
    z-index: 3;

    width: ${props => props.width || '47vw'};
    height: ${props => props.height || '24.5vw'};

    max-width: 700px;
    max-height: 350px;

    @media screen and (max-width: 766px) {
        width: 80vw;
        height: auto;
    }
`;