import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5vw 3vw 5vw 3vw;
    gap: 3vw;
    align-items: center;

    background-color: #e9f1ed;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 10vw 5vw;
        text-align: center;
    }
`;

export const Subcontainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 7vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        flex-wrap: wrap;
    }
`;

export const Selectbar = styled.div`
    min-width: 20vw;
    margin: 3em 0 0 0;

    @media screen and (max-width: 768px) {
        min-width: 25vw;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin: 5vw 0;
        width: 100%;
    }
`;

export const Content = styled.div`
    max-width: 70vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5vw 0;
        width: 100%;
        flex-wrap: wrap;
        max-width: 100%;
    }
`;

export const PartnerContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5vw;

    text-align: center;
`;

export const CustomCaps = styled.div`
    text-transform: uppercase;
    margin: 0.2vw 0;
    font-size: 16px;

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }

    @media screen and (max-width: 770px) {
        font-size: 12px;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin: 3vw 0 5vw 5vw;
        font-size: 1.1em;
        width: 90%;
        text-align: center;
    }
`;

export const CustomA = styled.a`
    text-decoration: none;
    color: inherit;

    :hover {
        font-weight: 600;
    }
`;

export const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.3vw;
    z-index: 0;

    margin-left: 1vw;

    background-color: white;
    width: 75%;

    padding: 2vw 1.5vw;
`