import styled from "styled-components";

export const Container = styled.div`
    width: 60%;
    background-color: #f7f3ec;
    overflow: hidden;
    margin-left: 22%;

    padding: 5vw 0vw;
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    text-align: left;
    // gap: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 100%;
        margin: 0;
        align-items: center;
        padding: 10vw 0;
    }
`;