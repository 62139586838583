import React, { useState } from 'react';
import { BsStop } from 'react-icons/bs';
import { Icon } from '../Icon';

import { PauseButton } from './styled';
import { GeneralContext } from '../../App';

export default function TextToSpeech(props) {
    const { textList } = props;

    const { hasPhone, hasTablet } = React.useContext(GeneralContext);
    const [ isSpeaking, setIsSpeaking ] = useState(false);

    const speechSynthesis = window.speechSynthesis;
    const msg = new SpeechSynthesisUtterance();

    const LANGUAGE = 'nl-NL'

    msg.lang = LANGUAGE;

    const nlVoices = speechSynthesis.getVoices().filter(v => v.lang.includes('nl'));
    const femaleVoice = nlVoices.filter(v => v.name === 'Ellen');
    
    // we prefer a female voice, otherwise take any other nl voice
    if (femaleVoice.length > 0) {
        msg.voice = femaleVoice[0];
    } else if (nlVoices.length > 0) {
        msg.voice = nlVoices[0];
    }

    msg.rate = 0.8;

    const cancel = () => {
        speechSynthesis.cancel();
    }

    const speak = (text) => {
        return new Promise((resolve) => {
            msg.text = text;
            speechSynthesis.speak(msg);

            msg.onend = () => {
                resolve()
            }
        })
    }

    const speechHandler = (i) => {
        if (i === textList.length) {
            setIsSpeaking(false);
            return null;
        }

        const originalText = textList[i].text;
        let currentText = textList[i].text;
        if (currentText.length > 1 && Array.isArray(currentText)) {
            currentText = '';
            for (let i=0; i < originalText.length; i++) {
                if (typeof originalText[i] === 'object') {
                    currentText += originalText[i].props.children;
                } else {
                    currentText += originalText[i];
                }
            }
        }

        speak(currentText).then(() => { speechHandler(i+1) });
    }

    const onClick = () => {
        if (isSpeaking) {
            cancel();
            setIsSpeaking(false);
            return null;
        } else if (textList?.length > 0) {
            setIsSpeaking(true); 
            speechHandler(0);
        }
    }

    return (
        <div 
            onClick={onClick}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: hasPhone ? '0' : '1vw', gap: hasPhone ? '3vw' : '1vw', cursor: 'pointer', position: 'relative', zIndex: '3' }}
        >
            {isSpeaking ?
                <PauseButton>
                    <BsStop 
                        size={hasPhone ? '7vw' : hasTablet ? '3vw' : 'clamp(20px, 2vw, 30px)'}
                        style={{ fill: '#dbb28c' }} 
                    />
                </PauseButton>
                : <Icon 
                    name='luidspreker' 
                    width={hasPhone ? '10vw' : hasTablet ? '4vw' : 'clamp(30px, 3vw, 40px)'}
                    />
            }
        </div>
    )
}