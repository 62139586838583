import React, { useRef, useState } from 'react';

import { Benieuwd, BlogEnPodcast, Medewerking } from '../../subpages';
import { Nieuwsbrieven, Oprichtster, GoedAangelegd, Container, Background, Nieuwsbrief } from './styled';
import { TitlePage, Typography, Image, Accordion, Ellipsis, TextField, Button, TextToSpeech } from '../../components';

import background1 from '../../assets/backgrounds/GA_Branding_Patroon-5a.png';
import background2 from '../../assets/backgrounds/GA_Branding_Patroon-5b.png';

import nadine from '../../assets/images/over/over.jpg';
import { GeneralContext } from '../../App';


const nieuwsbrieven = [
    {
        date: '2021-02-24',
        title: 'Naam nieuwsbrief 1'
    },
    {
        date: '2021-08-24',
        title: 'Naam nieuwsbrief 2'
    },
    {
        date: '2022-02-24',
        title: 'Naam nieuwsbrief 3 dit is een hele lange titel'
    },
    {
        date: '2022-08-24',
        title: 'Naam nieuwsbrief 4'
    },
    {
        date: '2023-02-24',
        title: 'Naam nieuwsbrief 5'
    },
    {
        date: '2023-08-24',
        title: 'Naam nieuwsbrief 5'
    }
]

export default function Over(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ profession, setProfession ] = useState('');

    const benieuwdRef = useRef(null);

    const getExpectedDate = () => {
        let expectedText = '';

        const lastNewsDate = new Date(nieuwsbrieven[nieuwsbrieven.length - 1].date);
        
        if (lastNewsDate.getMonth() < 7) {
            expectedText = `eind ${lastNewsDate.getFullYear()}`
        } else {
            expectedText = `begin ${lastNewsDate.getFullYear() + 1}`
        }

        return expectedText;
    }

    return (
        <Container>
            <GoedAangelegd>
                <Background src={background1} rotateDeg='180' zIndex='1' height='207vw' bottom='-200%' left='-140%' />
                {!hasPhone && <Background src={background2} rotateDeg='180' flip={true} zIndex='3' top='-20vw' right='-20vw' />}
                <Typography type='h1' style={{ color: '#92330f', margin: hasPhone ? '0 0 7vw 0' : '0 0 1vw 0' }}>Goed Aangelegd</Typography>
                <Typography style={{ width: hasPhone ? '75%' : '23%', margin: hasPhone && '0 0 5vw 0'}}>
                Borstvoeding is een intieme handeling met <span style={{ textDecoration: 'underline'}}>verrassend veel positieve effecten</span>. Niet alleen de gezondheidsvoordelen voor moeder en kind blijken indrukwekkend, maar ook de emotionele impact: een veilige hechting beïnvloedt levenslang het gedrag van een kind. Het is méér dan alleen voeding.
                </Typography>
                <Typography style={{ width: hasPhone ? '75%' : '23%', margin: hasPhone && '0 0 5vw 0' }}>
                De basis voor een fijne en goede borstvoedingsperiode begint bij een goede voorbereiding. De vier workshops van Goed Aangelegd geven houvast aan ouders, lactatiekundigen en verloskundigen. Door deze wetenschappelijk onderbouwde en breed gesteunde borstzorg op grote schaal te implementeren, maken we van elk borstvoedingsavontuur een succes.
                </Typography>
                <TextToSpeech />
            </GoedAangelegd>
            <Oprichtster>
                <TitlePage 
                    text='Oprichtster'
                    color='#be6942'
                />
                <Image src={nadine} alt='Foto Nadine' style={{ width: hasPhone ? '85vw' : 'auto', height: !hasPhone && '30vw'}} />
                <div style={{ width: hasPhone ? '80%' : '27%'}}>
                    <Typography type='h2' style={{ color: '#032d34', fontSize: '3em' }}>Oprichtster</Typography>
                    <Typography style={{ margin: hasPhone ? '10vw 0 0 0' : '1.5vw 0 0 0'}}>
                        Ik ben Nadine. Een enthousiasteling, een tikje eigenwijs én de drijvende kracht achter Goed Aangelegd. Als verloskundige en lactatiekundige miste ik een adequate borstvoedingszorg tijdens de zwangerschap.
                        <br/><br/>Na de bevalling breekt een intense periode aan, waarin het zwaar kan zijn om iets nieuws te leren. Bij tegenslag zie ik geregeld koppels tegen hun wil in hun borstvoedingswens terzijde schuiven. Dit is ontzettend jammer, want <span style={{ textDecoration: 'underline'}}>uit onderzoek blijkt</span> dat dit in veel gevallen met de juiste kennis en vaardigheden voorkomen had kunnen worden: bij koppels met de juiste voorbereiding op borstvoeding geven méér vrouwen langer borstvoeding.
                        <br/><br/>Daarom heb ik in samenwerking met onderwijskundigen, grafisch vormgevers en meer dan 50 (zorg)professionals borstvoedingsworkshops ontwikkeld. Hiermee worden zowel ouders als zorgverleners ondersteund bij de voorbereiding op het borstvoedingsavontuur.
                        <br/><br/><span style={{ fontWeight: '600'}}>Gedragen door verloskundigen, <br/>aangeboden door lactatiekundigen.</span>
                        <br/><br/>Met als gezamenlijke missie? Een fijne en goede borstvoedingsperiode voor iedereen die dat wil!
                    </Typography>
                </div>
            </Oprichtster>
            {false && <Medewerking />}
            {false && <Nieuwsbrieven>
                <Typography type='h2' style={{ color: '#b04f29', fontSize: '3em' }}>Nieuwsbrieven</Typography>
                <Typography style={{ width: hasPhone ? '80%' : '28%'}}>
                    Met onze nieuwsbrief houden we zorgverleners twee keer per jaar op de hoogte van nieuwste ontwikkelingen binnen Goed Aangelegd.
                    De volgende nieuwsbrief verschijnt {getExpectedDate()}.
                </Typography>
                <Accordion
                    headertext='Benieuwd naar eerdere edities? bekijk ze hier!'
                    headercolor='#145f68'
                    align='center'
                    width='80%'
                    style={{ margin: '0', width: '100%' }}
                >
                    {nieuwsbrieven.reverse().map((n, i) => {
                        const date = new Date(n.date);
                        return (
                            <div key={i} style={{ display: 'flex', textAlign: 'left', margin: hasPhone ? '3vw 0 3vw 0'  : '1vw 0 1vw 0' }}>
                                <Typography style={{ color: 'grey', width: hasPhone ? '25vw' : '5vw' }}>{date.toLocaleDateString('nl')}</Typography>
                                <Nieuwsbrief onClick={() => window.alert('download de behorende pdf')}>
                                    <Typography type='caps' style={{ color: '#256A72'}}>
                                        <Ellipsis text={n.title} maxLength={35} />
                                    </Typography>
                                </Nieuwsbrief>
                            </div>
                        )
                    })}
                </Accordion>
                <Typography style={{ width: hasPhone ? '80%' : '28%' }}>
                    Wil je als zorgprofessional je persoonlijke ervaring delen of je visie op de borstzorg bespreken? Of wil je als ouder zijnde een verhaal kwijt? Dit en andere bijdrages worden gewaardeerd en mag je sturen naar <a style={{ textDecoration: 'underline', color: 'inherit' }} href='mailto:goedaangelegd@gmail.com'>goed.aangelegd@gmail.com</a>.
                </Typography>
                <form style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: hasPhone ? '5vw' : '0.5vw' }}>
                    <Typography type='caps' style={{ color: '#b04f29', fontSize: '1.5em', margin: '2vw 0 0.5vw 0'}}>Schrijf je in<br/>voor de nieuwsbrief</Typography>
                    <TextField 
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        placeholder='Voornaam'
                        required={true}
                        customStyle='primary'
                        textAlign='center'
                    />
                    <TextField 
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        placeholder='Achternaam'
                        required={true}
                        customStyle='primary'
                        textAlign='center'
                    />
                    <TextField 
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder='E-mailadres'
                        email
                        required={true}
                        customStyle='primary'
                        textAlign='center'
                    />
                    <TextField 
                        value={profession}
                        onChange={(event) => setProfession(event.target.value)}
                        placeholder='Professie'
                        required={true}
                        customStyle='primary'
                        textAlign='center'
                    />
                    <Button text='Inschrijven' type='submit' style={{ margin: hasPhone ? '3vw 0' : '0.5vw 0'}} />
                </form>
            </Nieuwsbrieven>}
            {false && <BlogEnPodcast />}
            <Benieuwd givenRef={benieuwdRef} />
        </Container>
    );
}