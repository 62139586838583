import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vw 0;
    gap: 3vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }
`;

export const Boxes = styled.div`
    display: flex;
    gap: 3vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const WhiteBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2vw;
    
    width: 15vw;
    text-align: center;
    background-color: #ffffff;
    padding: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 80vw;
        // align-items: center;
        padding: 5vw;
        margin: 3vw 0;
    }
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    gap: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        gap: 3vw;
        font-size: 1em;
    }
`;

export const Review = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        gap: 2vw;
        margin: 2vw 0;
        font-size: 1em;
    }
`;

export const PaginationButton = styled.div`
    font-weight: ${props => props.selected ? '600' : '500'};

    &:hover {
        cursor: pointer;
    }
`;