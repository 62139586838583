import React, { createRef, useState, useEffect } from "react";

import { Typography } from "../../components";
import { Container, Subcontainer, Selectbar, Content, CustomCaps } from "./styled";
import { scrollIntoView, scrollToPos } from '../../helpers/ScrollToTop';

import * as Papa from 'papaparse';
import { titleCase } from '../../helpers/others';

import Partner from "./Partner";
import { GeneralContext } from "../../App";

const loadImages = require.context('../../assets/images/medewerking', true);

const CATEGORIES = ['Lactatiekundige',
    'Verloskundige',
    'Kraamverzorgster',
    'Huisarts',
    'Verpleegkundige',
    'Fysiotherapeut',
    'Psycholoog',
    'Hechtingsspecialist',
    'Seksuoloog',
    'Slaapspecialist',
    'Tandarts',
    'Diëtist',
    'Darmtherapeut',
    'Orthomoleculair therapeut',
    'Hormoonconsulent',
    'Onderzoeker',
    'Coach',
]


export default function Medewerking(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);
    const [ activePartner, setActivePartner ] = useState(null);
 
    // let uniqueCategories = [];
    // let categoryRefs = [];

    const [ partners, setPartners ] = useState([]);
    const [ uniqueCategories, setUniqueCategories ] = useState([]);
    const [ categoryRefs, setCategoryRefs ] = useState([]);

    const parseMedewerkingCsv = (data, columns) => {
        let dataRows = data.data;
  
        const lowerColumns = columns.map(c => c.toLowerCase());
  
        let newPartners = []

        let partnerCount = 0;
        for (let i = 1; i < dataRows.length; i++) {
            let row = dataRows[i];
            let dataObj = {}
            for (let j = 0; j < row.length; j++) {
                if (lowerColumns[j] == 'categorie') {
                    let categories = row[j].split(',').map(s => s.trim().toLowerCase());
                    dataObj[lowerColumns[j]] = categories;
                } else {
                    dataObj[lowerColumns[j]] = row[j];
                }
                
            }
  
            let photoName = '';
            if (dataObj['foto'] === 'ja') {
              photoName = titleCase(dataObj['voornaam']);
              let lastNameLst = dataObj['achternaam'].split(' ');
              for (let j = 0; j < lastNameLst.length; j++) {
                photoName += titleCase(lastNameLst[j]);
              }
            } else {
              photoName = 'ZonderProfielfoto'
            }
              
            photoName += '.extensie.jpg';
            dataObj['image'] = loadImages(`./${photoName}`);

            dataObj['id'] = partnerCount;
            partnerCount += 1;
  
            newPartners.push(dataObj);
        }
  
        // let uniqueCategories = [...new Set(newPartners.map(v => v['categorie']).flat())];
        const uniqueCategories = CATEGORIES.map(c => c.toLowerCase());
        
        setUniqueCategories(uniqueCategories);
        let categoryRefs = CATEGORIES.map((_) => createRef(null));
        setCategoryRefs(categoryRefs);
  
        setPartners(newPartners);
    }
  
      useEffect(() => {
        if (partners.length === 0) {
            fetch( './medewerking.csv' )
                .then( response => response.text() )
                .then( responseText => {
                    const data = Papa.parse(responseText);
                    
                    const columns = data['data'][0];
                    parseMedewerkingCsv(data, columns)
                })
        }
    }, [partners]);
    

    return (
        <Container>
            <Typography type='h1' style={{ color: '#145f68', fontSize: hasTablet ? '2em' : '2.5em' }}>Met medewerking van</Typography> 
            <Subcontainer>
                {!hasPhone && <Selectbar>
                    <div style={{ position: 'fixed' }}>
                        <Typography type='caps' style={{ color: '#145f68', fontSize: hasTablet ? '1em' : '1.1em', margin: '0 0 0.5vw 0' }}>Zorgprofessionals</Typography>
                        {uniqueCategories.map((category, i) => {
                            let cPartners = partners.filter(partner => partner['categorie'].includes(category));
                            if (cPartners.length === 0) {
                                return null;
                            }

                            return <CustomCaps key={i} onClick={() => {scrollToPos(categoryRefs[i], -300); }}>{category}</CustomCaps>
                        })}
                    </div>
                    {/* <Typography type='caps' style={{ color: '#145f68', fontSize: hasPhone && '1.1em', margin: hasPhone ? '10vw 0 0vw 0' : '2vw 0 0.5vw 0' }}>Productontwikkelaars</Typography> */}
                </Selectbar>}
                <Content>
                    {uniqueCategories.map((c, i) => {
                        let cPartners = partners.filter(partner => partner['categorie'].includes(c));

                        if (cPartners.length === 0) {
                            return null;
                        }

                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', margin: hasPhone ? '0 0 7vw 0' : '0 0 3vw 0', width: '100%' }} ref={categoryRefs[i]} key={i}>
                                <Typography type='h2' style={{ color: '#256A72', textAlign: 'center', fontSize: hasTablet ? '1.5em' : '1.7em' }}>{titleCase(c)}</Typography>
                                <div style={{ display: 'flex', justifyContent: hasPhone && 'center', gap: '3vw', flexWrap: 'wrap', margin: hasPhone ? '5vw 0' : '2vw 0 0 0' }}>
                                    {cPartners.map((item, i) => (
                                        <Partner key={i} item={item} activePartner={activePartner} setActivePartner={setActivePartner} />
                                    ))}
                                </div>
                            </div>
                        )
                    })}
                </Content>
            </Subcontainer>
        </Container>
    )
}