import styled from "styled-components";

export const ModalBox = styled.form`
    position: absolute;

    max-height: ${props => props.height || '50vh'};
    left: ${props => props.left || '37%'};
    top: ${props => props.top || '30%'};
    width: ${props => props.width || '24vw'};
    background-color: white;
    box-shadow: 24;

    padding: 1.5vw 1.5vw 2vw 1.5vw;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;

    border: solid 1px white;
    border-radius: 1%;

    outline: none;

    overflow: scroll;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 3vw;
        left: 7.5vw;
        width: 80vw;
        top: 20vw;
        margin: 0;
        max-height: 80vh;
    }
`