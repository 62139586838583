import React from 'react';

import usePagination from '@mui/material/usePagination';

import { Typography } from '../../components';

import { WhiteBox, Pagination, PaginationButton, Review } from './styled';

import ouders from '../../assets/images/illustrations/ouders.png';
import lactatiekundige from '../../assets/images/illustrations/lactatiekundige.png';
import verloskundige from '../../assets/images/illustrations/verloskundige.png';
import { GeneralContext } from '../../App';

const fixedReviews = [
    {
        text: 'Hier staat een korte review van ouders over jouw diensten. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'ouders',
        name: 'Naam namen',
        date: 'Datum'
    },
    {
        text: 'Hier staat een korte review van een lactatiekundige over jouw diensten. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'lactatiekundige',
        name: 'Naam namen',
        date: 'Datum'
    },
    {
        text: 'Hier staat een korte review van een verloskundige over jouw diensten. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'verloskundige',
        name: 'Naam namen',
        date: 'Datum'
    },
    {
        text: 'Hier staat een korte review van ouders 2 over jouw diensten. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'ouders',
        name: 'Naam namen 2',
        date: 'Datum2'
    },
    {
        text: 'Hier staat een korte review van een verloskundige in opleiding over de masterclasses. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'Verloskundige in opleiding',
        name: 'Naam namen 2',
        date: 'Datum2'
    },
    {
        text: 'Hier staat een korte review van ouders 3 over jouw diensten. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'ouders',
        name: 'Naam namen 3',
        date: 'Datum2'
    },
    {
        text: 'Hier staat een korte review van ouders 4 over jouw diensten. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'ouders',
        name: 'Naam namen 4',
        date: 'Datum2'
    },
    {
        text: 'Hier staat een korte review van ouders 2 over jouw diensten. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'ouders',
        name: 'Naam namen 2',
        date: 'Datum2'
    },
    {
        text: 'Hier staat een korte review van ouders 5 over jouw diensten. Pellentesque orci nisi, dapibus ut leo sit amet, porta tincidunt libero. Nullam pellentesque, felis eu ultricies finibus, dolor nisl fringilla urna, at ornare dui ligula ac neque. Donec libero ipsum, bibendum eget tincidunt non, mollis hendrerit lorem. Cras tempor tellus in elit semper feugiat. Nunc in nisi at nibh imperdiet scelerisque.',
        category: 'ouders',
        name: 'Naam namen 5',
        date: 'Datum2'
    },
]

export function ErvaringenBox(props) {
    const { category } = props;

    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const MAX_REVIEWS_SHOWN = 2;

    let textCategory = category;
    if (category !== 'ouders') {
        textCategory = category.concat('n')
    }
    let reviews = fixedReviews.filter(r => r.category === category);

    const { items } = usePagination({
        count: reviews.length / MAX_REVIEWS_SHOWN > 0 ? reviews.length / MAX_REVIEWS_SHOWN : 1,
    });

    const getCurrentReviews = () => {
        const currentPage = items.filter(p => p.selected)[0]?.page;

        if (currentPage) {
            return reviews.slice((currentPage - 1)*MAX_REVIEWS_SHOWN, (currentPage)*MAX_REVIEWS_SHOWN);
        }

        return reviews;
    }

    return (
        <WhiteBox>
            {category === 'ouders' ? 
                <img alt={category} src={ouders} /> : 
                category === 'lactatiekundige' ? 
                <img alt={category} src={lactatiekundige} /> : <img alt={category} src={verloskundige} />
            }
            <Typography type='h2' style={{ fontSize: hasPhone ? '1.8em' : '1.7em', color: '#b04f29', margin: hasPhone && '3vw 0 7vw 0' }}>
                <span style={{ textTransform: 'capitalize' }}>{textCategory} </span>{!hasPhone && <br/>}
                    zeggen...
            </Typography>
            {getCurrentReviews().map((r,i) => (
                <Review>
                    <div style={{ fontWeight: '600', margin: hasPhone ? '0 0 1vw 0' : '0 0 0.1vw 0'}}>{r.name} | {r.date}</div>
                    <span>{r.text}</span>
                </Review>
            ))}
            <Pagination>
                {items.length > 2 && items.map(({ page, type, selected, ...item }, index) => {
                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        return <div>...</div>
                    } else if (type === 'page') {
                        return <PaginationButton selected={selected} {...item}>{page}</PaginationButton>
                    } else {
                        let content = '<';
                        if (type === 'next') {
                            content = '>';
                        }
                        return <PaginationButton {...item}>{content}</PaginationButton>
                    }
                })}
            </Pagination>
        </WhiteBox>
    )
}