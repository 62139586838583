import React, { useEffect, useState, createRef, useCallback, useRef } from 'react';
import TripleCarouselComponent from './TripleCarouselComponent';

import { TripleContainer, TripleArrow } from './styled';

import { shuffle, mod, range } from '../../helpers/others';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { GeneralContext } from '../../App';

export default function TripleCarousel(props) {
  const { hasPhone, hasTablet } = React.useContext(GeneralContext);

  const { itemList, imagePath, contentType } = props;

  const numberShown = hasPhone ? 2 : 3;
  const [ indices, setIndices ] = useState(range(0, numberShown, 1));
  
  const [ visibleItems, setVisibleItems ] = useState(Array(numberShown).fill(true));
  const [ items, setItems ] = useState([]);

  // useRef instead of useState to prevent re-rendering!
  const timeoutId = useRef(null);

  const updateVisibilityItems = (newStatus, currIndex) => {
    if (newStatus) {
      // don't show the remaining items
      let newVisibility = Array(numberShown).fill(false);
      newVisibility[currIndex] = true;
      setVisibleItems(newVisibility);
      clearTimeout(timeoutId.current);
    } else {
      // show all again
      setVisibleItems(Array(numberShown).fill(true));
      clearTimeout(timeoutId.current);
    }
  }

  const updateOrderItems = useCallback(( addValue ) => {
    const reorderItems = (newIndices) => {
      const orderedItems = newIndices.map(i => itemList[i]);
      setItems(orderedItems);
    }

    const newIndices = indices.map(v => mod(v+addValue, itemList.length));
    setIndices(newIndices);
    reorderItems(newIndices);
  }, [indices, itemList])

  useEffect(() => {
    if (items.length === 0) {
      let ketenItems = itemList;
      ketenItems = shuffle(itemList);
      ketenItems = ketenItems.map(v => ({...v, nodeRef: createRef(null)}))
      setItems(ketenItems);
    }

    // Check if one of them is false and the rest true EXACTLY
    const actualVisibleItems = visibleItems.filter(p => p === true);
    if ((actualVisibleItems.length === visibleItems.length)) {
      clearTimeout(timeoutId.current);
      const timeout = setTimeout(() => ( ( updateOrderItems(-1), timeoutId.current = null ) ), 5000);
      timeoutId.current = timeout;
    } else if (actualVisibleItems.length === 1) {
      clearTimeout(timeoutId.current);
    }

  }, [itemList, items.length, visibleItems, updateOrderItems, indices]);

  if (items.length > 0) {
    return (
      <TripleContainer>
          {!hasPhone && <TripleArrow
            onClick={() => updateOrderItems(1)}
            contentType={contentType}
          >
            {'<'}
          </TripleArrow>}
          <div style={{ display: 'flex', gap: hasPhone ? '10px' : '30px' }}>
            {items.slice(0,numberShown).map((v, i) => (
              <TripleCarouselComponent
                visible={visibleItems[i]}
                showInfo={visibleItems.filter(v => v).length !== items.slice(0,numberShown).length && visibleItems[i]}
                currIndex={i}
                key={i} 
                item={v} 
                imagePath={imagePath} 
                contentType={contentType} 
                updateVisibilityItems={updateVisibilityItems} />
              )
            )}
          </div>
          {!hasPhone && <TripleArrow
            onClick={() => updateOrderItems(-1)}
            contentType={contentType}
          >
            {'>'}
          </TripleArrow>}
      </TripleContainer>
    );
  }

  return null;
}