import React, { useEffect, useState, useRef } from 'react';
import Image from './Image';
import { mod } from '../../helpers/others';
import { GeneralContext } from '../../App';

export default function ImageCarousel(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const { images } = props;

    const [ currentId, setCurrentId ] = useState(0);

    const timeoutId = useRef(null);

    const duration = 4000;

    useEffect(() => {
        if (!timeoutId.current) {
            const timeout = setTimeout(() => { setCurrentId(mod(currentId + 1, images.length)); timeoutId.current = null }, duration);
            timeoutId.current = timeout;
        }
    })

    return (
        <Image src={images[currentId]} style={{ width: hasPhone ? '80vw' : '15vw', height: hasPhone ? '80vw' : '20vw' }} borderColor='#e9f1ed' />
    )
}