import React from "react";
import { Caps, H1, H2, Paragraph } from "./styled";

export default function Typography(props) {
    switch(props.type) {
        case 'h1':
            return <H1 style={props.style} {...props}>{props.children}</H1>;
        case 'h2':
            return <H2 style={props.style} {...props}>{props.children}</H2>;
        case 'caps':
            return <Caps style={props.style} {...props}>{props.children}</Caps>
        default:
            return <Paragraph style={props.style} {...props}>{props.children}</Paragraph>;
    }
}