import React, { useState, useEffect } from 'react';

import { Typography, Image } from '../../components';
import { PartnerContent, CustomA, InfoBox } from './styled';
import { GeneralContext } from '../../App';

export default function Partner(props) {
    const { item, activePartner, setActivePartner } = props;
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ showInfo, setShowInfo ] = useState(false);

    const onClick = () => {
        const currentActive = item && activePartner && Object.keys(activePartner).length > 0 && activePartner.id === item.id;
        if (currentActive || hasPhone || hasTablet) {
            setShowInfo(false);
            setActivePartner({});
        } else {
            setShowInfo(true);
            setActivePartner(item)
        }
    }

    useEffect(() => {
        const currentActive = item && activePartner && Object.keys(activePartner).length > 0 && activePartner.id === item.id;
        if (!currentActive && showInfo) {
            setShowInfo(false);
        }
    }, [activePartner, item, showInfo])

    let imgSize = '180px';
    if (hasPhone) {
        imgSize = '150px'
    } else if (hasTablet) {
        imgSize = '160px'
    }

    return (
        <div style={{ display: 'flex', cursor: !hasPhone && !hasTablet && 'pointer', margin: hasPhone && '3vw 0', height: 'min-content', }} onClick={onClick}>
            <PartnerContent>
                <Image objectFit='cover' style={{ width: imgSize, height: imgSize }} src={item.image} />
                <Typography type='h2' style={{ fontSize: hasPhone ? '1em' : hasTablet ? '1.1em' : '1.3em' }}>{item['voornaam'] + ' ' + item['achternaam']}</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '14px', margin: '0.5vw 0 0 0', maxWidth: imgSize }}>
                    <Typography>{item['functie1']}</Typography>
                    <Typography>{item['functie2']}</Typography>
                </div>
            </PartnerContent>
            {showInfo && <InfoBox>
                <div>
                    <Typography><span style={{ fontWeight: '600'}}>Naam: </span>{item.voornaam + ' ' + item['achternaam']}</Typography>
                    <div>
                        <Typography style={{ display: 'inline'}}><span style={{ fontWeight: '600'}}>Functie: </span>{item['functie1']}</Typography> 
                        {item['functie2'].length > 0 && <Typography style={{ display: 'inline'}}>{' | ' + item['functie2']}</Typography>}
                    </div>
                    <Typography><span style={{ fontWeight: '600'}}>Praktijk: </span>{item['bedrijf']}</Typography>
                    <Typography><span style={{ fontWeight: '600'}}>Website: </span>
                        <CustomA href={`https://${item.website}`} target="_blank">{item['website']}</CustomA>
                    </Typography>
                    <Typography style={{ margin: '0.5vw 0'}}><span style={{ fontWeight: '600'}}>Bijgedragen aan: </span>{item['bijdrage']}</Typography>
                </div>
                {item['quote'].length > 1 && 
                    <Typography style={{ fontStyle: 'italic', textAlign: 'left', fontSize: '12px', color: '#145f68', overflow: 'hidden' }}>
                        "{item['quote']}"
                    </Typography>
                }
            </InfoBox>}
        </div>
    )
}