import React, { useState, useEffect } from 'react';
import { Button, Typography, Icon, ReactIcon, TitlePage, Image } from '../../components';
import { Circle, Main, Introduction, Afleveringen } from './styled';
import { GeneralContext } from '../../App';
import * as Papa from 'papaparse';

import ouders from '../../assets/images/illustrations/ouders.png';
import lactatiekundige from '../../assets/images/illustrations/lactatiekundige.png';
import verloskundige from '../../assets/images/illustrations/verloskundige.png';
import { Link } from 'react-router-dom';

export default function Podcast(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ episodes, setEpisodes ] = useState([]);

    const lst = [
        {
            'name': 'ouders',
            'image': ouders,
            'color': '#ECB991'
        },
        // {
        //     'name': 'lactatiekundigen',
        //     'image': lactatiekundige,
        //     'color': '#639796'
        // },
        // {
        //     'name': 'verloskundigen',
        //     'image': verloskundige,
        //     'color': '#CADED4'
        // },
    
    ]

    const parsePodcastCsv = (data, columns) => {
        let dataRows = data.data;

        let newEpisodes = []
        for (let i = 1; i < dataRows.length; i++) {
            let row = dataRows[i];
            let dataObj = {}
            for (let j = 0; j < row.length; j++) {
                dataObj[columns[j]] = row[j];
            }
            newEpisodes.push(dataObj);
        }

        setEpisodes(newEpisodes);
    }

    useEffect(() => {
        if (episodes.length === 0) {
            fetch( './podcast.csv' )
                .then( response => response.text() )
                .then( responseText => {
                    const data = Papa.parse(responseText);
                    
                    const columns = data['data'][0];
                    parsePodcastCsv(data, columns)
                })
        }
    }, [episodes]);
    
    return (
        <Main>
            <Introduction>
                <Typography type='h1' style={{ color: '#92320F', fontSize: hasPhone ? '2em' : hasTablet ? '2.5em' : '3em', margin: hasPhone ? '0 0 5vw 0' : hasTablet ? '0 0 2vw 0' : '0 0 2vw 0' }}>Podcast</Typography>
                <Typography style={{ width: hasPhone ? '80%' : '50%' }}>De podcast Borstvoedingsbabbel van Goed Aangelegd
                    biedt kennis over de wereld van borstvoeding. De
                    seizoenen zijn opgesplitst in ouders, lactatiekundigen en
                    verloskundigen. Host en oprichtster Nadine Somers gaat in
                    elke aflevering in gesprek met experts, bespreekt vragen
                    van luisteraars en deelt praktische adviezen en persoonlijke
                    verhalen. Elke podcast heeft een eigen video en blog.
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5vw', margin: '3vw 0 0 0' }}>
                    <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => window.open(`https://${episodes[0]['algemeenspotify']}`, '_blank')}>
                        <Icon 
                            name='luidspreker' 
                            fill='white'
                            circleFill='#145F68'
                            hoverCircleFill='red'
                            width={hasPhone ? '8vw' : '2vw'}
                        />
                        <Typography style={{ margin: '0.5vw 0 0 0'}}>BELUISTER ZE HIER</Typography>
                    </div>
                    <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => window.open(`https://${episodes[0]['algemeenyoutube']}`, '_blank')}>
                        <ReactIcon name='youtube' circleFill='#145F68' noHover={true} noLink={true}/>
                        <Typography style={{ margin: '0.5vw 0 0 0'}}>BEKIJK ZE HIER</Typography>
                    </div>
                </div>
            </Introduction>
            <Afleveringen>
                <TitlePage 
                    color='#92330f'
                    text='Podcast'
                />
                <Typography type='h2' style={{ maxWidth: '80vw' }}>Ontdek welke afleveringen er voor jou klaarstaan!</Typography>
                <div style={{ display: 'flex', gap: '5vw', marginTop: '2vw'}}>
                    {lst.map((l, i) => {
                        let categoryEpisodes = episodes.filter(v => v.type === l.name);

                        return (
                            <div style={{ backgroundColor: 'white', width: hasPhone ? '75vw' : hasTablet ? '30vw' : '22vw', display: 'flex', padding: hasPhone && '5vw', flexDirection: 'column', alignItems: 'center' }}>
                                <Image src={l.image} style={{ width: hasPhone ? '80vw' : hasTablet ? '28vw' : '20vw', height: hasPhone ? '80vw' : hasTablet ? '28vw' : '20vw' }} />  
                                <Typography type='h2' style={{ color: l.color, fontSize: '1.5em', margin: '0 0 0.5vw 0' }}>Borstvoedingsbabbel</Typography>
                                <Typography style={{ margin: '0 0 1vw 0'}}>Voor {l.name}</Typography>
                                {categoryEpisodes.length > 0 && categoryEpisodes.map((e, j) => (
                                    <Link 
                                        style={{ width: '100%', padding: '0 0 1vw 2vw', textAlign: 'left', textDecoration: 'none', color: 'black', cursor: 'pointer' }} 
                                        to={`/podcast/${l.name}/${e.nummer}`}>
                                        #{e.nummer} {e.naam}
                                    </Link>
                                ))}
                                {l.name === 'ouders' && <Button 
                                    to={`/podcast/${l.name}`}
                                    text='Bekijk ze hier allemaal'
                                    style={{ margin: '1vw 0 1vw 0'}}
                                />}
                            </div>
                        )
                    })}
                    
                </div>
            </Afleveringen>
        </Main>
    );
}