import React from 'react';
import { Boxes, Container } from './styled';

import { TitlePage, Typography } from '../../components';
import { ErvaringenBox } from './ErvaringenBox';
import { GeneralContext } from '../../App';

export default function Ervaringen(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    return (
        <Container>
            <TitlePage 
                text='Ervaringen'
                color='#be6942'
            />
            <Typography type='h1' style={{ color: '#92330f', margin: hasPhone && '0 0 5vw 0' }}>Ervaringen</Typography>
            <Boxes>
                {['ouders', 'lactatiekundige', 'verloskundige'].map((d, i) => (
                    <ErvaringenBox category={d} />
                ))}
            </Boxes>
        </Container>
    )
}