import styled from "styled-components";

export const Text = styled.span`
    font-size: ${props => props.textSize || '16px'};
    color: #9B4625;
    font-family: Gayathri;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 14px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        margin-top: 0.7vw;
        font-size: 14px;
    }
`;

export const Check = styled.div`
    position: relative;
    cursor: ${props => props.clickable ? 'pointer' : 'default'};
`;

export const Box = styled.div`
    position: absolute;
    bottom: ${props => props.boxBottom || '2px'};
    left: ${props => props.boxLeft || '6px'};
    z-index: 1;
    width: ${props => props.boxWidth || '22px'};
`;

export const Circle = styled.div`
    width: ${props => props.circleWidth || '25px'};
    height: ${props => props.circleWidth || '25px'};
    background-color: #E7CDB5;

    border: 1px solid #E7CDB5;
    border-radius: 50%;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: ${props => props.text ? '1vw' : '0'};

    margin: 10px 0;
    // z-index: 2;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin: 1vw 0;
        gap: ${props => props.text ? '3vw' : '0'};
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        // gap: ${props => props.text ? '2vw' : '0'};
    }
`;

