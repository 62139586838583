import styled from "styled-components";

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    gap: 0.5vw;

    color: ${props => props.color || 'black'};

    cursor: pointer;

    :hover {
        font-weight: bold;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        gap: 3vw;
    }
`;

export const Arrow = styled.span`
    font-family: Amelie;
    font-size: 30px;
    height: min-content;
    font-weight: normal;

    margin-left: ${props => props.shouldRotate ? '8px' : '5px'};
    margin-bottom: ${props => props.shouldRotate ? '10px' : '15px'};

    transform: ${props => props.shouldRotate ? 'rotate(90deg)' : 'none'};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 2.2em;
    }
`;