import styled from 'styled-components';

export const Arrow = styled.div`
    position: absolute;
    right: 0.5vw;
    top: -0.2vw;
    font-family: Amelie;
    font-size: 2vw;
    transform: rotate(90deg);
    padding-bottom: 0; 
    color: var(--humus);

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 7vw;
        right: 1vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 4.5vw;
        right: 1vw;
        top: -0.5vw;
    }
`;

export const Header = styled.div`
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2vw;

    width: ${props => props.width || '8vw'};
    background-color: var(--warmwhite);
    padding: ${props => props.disabled ? '0.5vw 1.2vw 0.2vw 0' : '0.5vw 1.2vw 0.2vw 0.8vw'};
    opacity: ${props => props.disabled ? '0.5' : '1'};

    cursor: ${props => !props.disabled && 'pointer'};

    &:hover ${Arrow} {
        color: ${props => !props.disabled && 'var(--expectation)'};
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 14px;
        width: ${props => props.phoneWidth || '48vw'};
        padding: ${props => props.disabled ? '2vw 0 1vw 0' : '2vw 0 1vw 1vw'};
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 16px;
        width: ${props => props.tabletWidth || '50vw'};
        padding: ${props => props.disabled ? '1vw 0 0.5vw 0' : '1vw 0 0.5vw 1vw'};
    }
`;

export const Container = styled.div`
    font-family: Gayathri;
    margin: ${props => props.disabled ? '0 0 0.5vw 0' : '0.5vw 0'};
`;

export const ListContainer = styled.div`
    position: absolute;
    max-height: 8vw;
    overflow-y: visible;
    overflow-x: hidden;
    z-index: 1;

    ::-webkit-scrollbar {
        width: 0.6vw; /* Mostly for vertical scrollbars */
    }

    ::-webkit-scrollbar-thumb { /* Foreground */
        background: var(--believe);
        border-radius: 100px;
    }

    ::-webkit-scrollbar-track { /* Background */
        background: var(--warmwhite);
        border-radius: 100px;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        max-height: 23vw;
        ::-webkit-scrollbar {
            width: 1.5vw; /* Mostly for vertical scrollbars */
        }
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        max-height: 11vw;
        ::-webkit-scrollbar {
            width: 1vw; /* Mostly for vertical scrollbars */
        }
    }
`;

export const List = styled.ul`
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    list-style: none;

    width: ${props => props.width || '8vw'};
    padding: 0.5vw 0.8vw 0.2vw 0.8vw;
    background-color: var(--soil);

    cursor: pointer;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 14px;
        width: ${props => props.phoneWidth || '48vw'};
        padding: 2vw 0 1vw 1vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 16px;
        width: ${props => props.tabletWidth || '50vw'};
        padding: 1vw 0 0.5vw 1vw;
    }
`;

