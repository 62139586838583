import React from "react";
import { Button, Typography } from "../../components";

export default function OudersEvaluatie(props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5vw 0', gap: '2vw', minHeight: '40vw' }}>
            <Typography type='h2'>Evaluatie van de workshops</Typography>
            <Typography>Klik op een van de knoppen beneden om de evaluatie uit te voeren.</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2vw', margin: '1vw 0 0 0' }}>
                <Button 
                    buttonstyle='primary'
                    target='_blank'
                    href='https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAAC3BYPZUNEtXQks4NVBDUjdNTENaRTlVQU5UOTcwVC4u'
                    text='Evaluatie CENTERING FEEDING 1 & 2 (workshops tijdens de zwangerschap)'
                />
                <Button 
                    buttonstyle='primary'
                    target='_blank'
                    href='https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAAC3BYPZUMUIyVEg4VUJLSUJJUkFDU0xVVFdPQldWQi4u'
                    text='Evaluatie CENTERING FEEDING 3 (workshop tijdens de kraamperiode)'
                />
            </div>
        </div>
    )
}