import styled from "styled-components";

export const Container = styled.div`
    background-color: #f7f3ec;
    width: 100%;
    padding: 5vw 0;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    z-index: 2;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        gap: 10vw;
    }
`;