import React, { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router";
import { MdOutlineMenu, MdOutlineMenuOpen } from 'react-icons/md';

import { Button, Typography } from '..';
import { Content, LogoBar, Logo, ButtonBar, StickyDiv, SmallLogo, ResponsiveMenu, BoldLink, TemporaryBanner } from './styled';

// import logotext from '../../assets/elearning-logo.png';
import logoText from '../../assets/logo-text.png';
import logo from '../../assets/logo.png';
import { GeneralContext } from "../../App";

export default function Menu(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ isTop, setTop ] = useState(true);
    const [ menuOpen, setMenuOpen ] = useState(false);

    const { pathname } = useLocation();

    const hasResp = hasPhone || hasTablet;

    const buttonBarLinks = [
        { text: 'Ouders', link: 'ouders' },
        { text: 'Lactatiekundigen', link: 'lactatiekundigen'},
        { text: 'Verloskundigen', link: 'verloskundigen' },
        { text: 'Logo', link: '' },
        { text: 'Over ons', link: 'over' },
        { text: 'Podcast', link: 'podcast' },
        { text: 'Contact', link: 'contact' },
    ]

    useEffect(() => {
        window.onscroll = () => {
            var body = document.body,
                html = document.documentElement;
            var height = Math.max( body.scrollHeight, body.offsetHeight, 
                html.clientHeight, html.scrollHeight, html.offsetHeight );

            window.scrollY > 0 && height > 1100 ? setTop(false) : setTop(true);

            return () => (window.onscroll = null);
        }
    });

    return (
        <StickyDiv isTop={isTop}>
            {(isTop && !menuOpen) && 
                <TemporaryBanner>
                    Welkom op de website van Goed Aangelegd, waar we borstvoedingsavonturen ondersteunen. Hoewel de site nog in aanbouw is en het design nog niet helemaal is afgerond, kun je alle informatie al vinden. Bedankt voor je begrip terwijl we blijven werken aan de website.
                </TemporaryBanner>
            }
            <Content isTop={isTop}>
                {isTop && !hasPhone && !hasTablet && <LogoBar to='/'>
                    <Logo src={logoText} />
                </LogoBar>}
                {(hasResp) && 
                    <div 
                        onClick={() => setMenuOpen(!menuOpen)}
                        style={{ position: 'absolute', left: '3vw', top: 'auto%', height: 'inherit' }}
                    >
                        {menuOpen ? 
                            <MdOutlineMenuOpen 
                                size={hasTablet ? '3vh' : '3.5vh'}
                                style={{ fill: 'rgb(20, 95, 104)', stroke: 'none'}} 
                            /> :
                            <MdOutlineMenu 
                                size={hasTablet ? '3vh' : '3.5vh'}
                                style={{ fill: 'rgb(20, 95, 104)', stroke: 'none'}} 
                            />
                        }
                    </div>
                }
                <ButtonBar>
                    {buttonBarLinks.map(v => {
                        if (v.text !== 'Logo' && !hasResp) {
                            return (
                                <Button 
                                    key={v.text}
                                    width={hasTablet ? '15vw' : '10vw'}
                                    to={`/${v.link}`}
                                    text={v.text}
                                    buttonstyle={'fifthly'}
                                    inverseStyle={matchPath(pathname, `/${v.link}`)}
                                    minWidth='150px'
                                />
                            )
                        } else if ((!isTop || hasResp) && v.text === 'Logo') {
                            return (
                                <LogoBar key={v.text} to='/' onClick={() => setMenuOpen(false)}>
                                    <SmallLogo src={logo} />
                                </LogoBar>
                            )
                        }

                        return null;
                    })}
                </ButtonBar>
            </Content>
            {(hasResp) &&
                <ResponsiveMenu open={menuOpen} onClick={() => setMenuOpen(false)}>
                    {buttonBarLinks.map((v,i) => {
                        if (v.text !== 'Logo') {
                            return (
                                <Button 
                                    key={i}
                                    width={hasPhone ? '50vw' : '25vw'}
                                    buttonstyle='primary'
                                    to={v.link}
                                    text={v.text}
                                    style={{ fontSize: '1em'}}
                                />
                            )
                        }
                        return null;
                    })}
                    {/* <Button
                        iconName='login'
                        iconProps={{ width: hasPhone ? '6vw' : '4vw' }}
                        href='https://elearning.goedaangelegd.nl'
                        buttonstyle='thirdly'
                        text={'login'}
                        style={{ margin: '3vw 0 0 0', fontSize: hasPhone ? '0.8em' : hasTablet ? '1.3em' : '1em'}}
                    /> */}
                </ResponsiveMenu>
            }
        </StickyDiv>
    )
}