import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    // display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.widthStyle || 'default'};
    height: ${props => props.heightStyle || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};

    object-fit: cover;
`;  

export const Introduction = styled.div`
    display: flex;
    width: 100%;
    padding: 5vw 0vw;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    background-color: #F6F3EC;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-wrap: wrap;
        flex-direction: column; 
        align-items: center;
    }
`;

export const IntroductionText = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items: center;
    width: 500px;
    max-width: 40vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 100%;
        gap: 5vw;
    }
`;

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: #FDFCFA;
    width: 100px;
    height: 100px;
`;

export const MOMS = styled.div`
    width: 100%;
    background-color: #FDFCFA;
    padding: 5vw 0vw;
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }   
    
    @media screen and (max-width: 1023px) {
        padding: 10vw 0;
    } 
`;

export const Sponsoring = styled.div`
    width: 100%;
    background-color: #F1E2D4;
    padding: 5vw 0vw;
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: center;
    flex-direction: row;
    // align-items: center;
    gap: 10vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 10vw 0;
        align-items: center;
        flex-direction: column-reverse;
    } 
    
    
`;