import React, { useState } from 'react';

import { Modal, TextField, Button } from '../../components';

export default function AndersModal(props) {
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ profession, setProfession ] = useState('');
    const [ vraag, setVraag ] = useState('');

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            onSubmit={props.onSubmit}
            title='Neem contact op'
        >
            <TextField 
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                placeholder='Voornaam'
                required={true}
                customStyle='primary'
            />
            <TextField 
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                placeholder='Achternaam'
                required={true}
                customStyle='primary'
            />
            <TextField 
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder='E-mailadres'
                email
                required={true}
                customStyle='primary'
            />
            <TextField 
                value={profession}
                onChange={(event) => setProfession(event.target.value)}
                placeholder='Professie'
                required={true}
                customStyle='primary'
            />
            <TextField 
                value={vraag}
                onChange={(event) => setVraag(event.target.value)}
                placeholder='Vraag of opmerking'
                required={true}
                customStyle='primary'
                textArea={true}
            />
            <Button text='Versturen' type='submit' style={{ margin: '1vw 0 0 0'}} />
        </Modal>
    )
}