import React, { useEffect, useState, useRef } from 'react';

import { Container, Introduction, IntroductionText, Circle, MOMS, Background, Sponsoring } from './styled';
import { Typography, Video, Image, TitlePage, List, ListItem, Button } from '../../components';
import { GeneralContext } from '../../App';

import sponsoring from '../../assets/images/home/sponsoring.png';
import photo from '../../assets/images/sponsoring/sponsoring.JPG';
import mom1 from '../../assets/backgrounds/mom1.png';
import mom2 from '../../assets/backgrounds/mom2.png';

export default function MOM(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);
    const [ imageHeight, setImageHeight ] = useState(null);

    const childSponsoringRef = useRef(null);

    useEffect(() => {
        if (!imageHeight) {
            setImageHeight(childSponsoringRef.current?.clientHeight);
        }
    }, [imageHeight])

    return (
        <Container>
            <Introduction>
                {!hasPhone && <Background src={mom1} zIndex='1' top='0' right={hasTablet ? '-300px' : '0'} height='100%' />}
                <IntroductionText>
                    <Typography type='h1' style={{ color: '#be6942', margin: hasPhone ? '3vw 0 5vw 0' : '3vw 0 2vw 0', width: hasPhone ? '80vw' : hasTablet ? '40vw' : 'default', fontSize: hasTablet ? '1.6em' : 'clamp(18px, 2.2em, 46px)' }}>Er sterft één vrouw elke twee minuten aan complicaties tijdens zwangerschap of bevalling</Typography>
                    <Circle>
                        <Image src={sponsoring} width='80px' height='60px' style={{ objectFit: 'fill', border: 'none' }} />
                    </Circle>
                </IntroductionText>
                <div style={{ zIndex: '5'}}>
                    <Video videoId='me5nnVvRP8w' width={'45vw'} height={'25vw'} />
                </div>
            </Introduction>
            <MOMS>
                <TitlePage 
                    color='#92330f'
                    text='Mothers On the Move'
                />
                <Typography type='h2' style={{ color: '#92330f', fontSize: hasPhone || hasTablet ? '2.5em' : '45px', margin: hasPhone ? '0 0 5vw 0' : hasTablet ? '0 0 3vw 0' : '0 0 1vw 0', maxWidth: '85vw' }}>Mothers On the Move</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', gap: hasPhone ? '5vw' : hasTablet ? '3vw' : '1vw', maxWidth: hasPhone ? '85vw' : hasTablet ? '75vw' : '50vw', width: '600px'}}>
                    <Typography>Stel je voor: vijf kilometer lopen met weeën over ruig terrein om het dichtstbijzijnde ziekenhuis te bereiken voor een veilige bevalling. Voor veel zwangere vrouwen in Kenia is dit een onmogelijke reis, met soms dodelijke gevolgen.</Typography>
                    <Typography>Om deze vrouwen in Kenia te ondersteunen, werd Mothers On the Move (MOM) in 2013 opgericht. MOM zorgt voor transport naar het ziekenhuis voor barende vrouwen op de Nyakach Plateau met behulp van piki piki's (motorfietsen). Sinds 2013 heeft MOM meer dan 2.000 vrouwen veilig naar het ziekenhuis gebracht. Deze inzet heeft geleid tot een daling van moeder- en kindersterfte op de Nyakach Plateau.</Typography>
                </div>
            </MOMS>
            <Sponsoring>
                {!hasTablet && <TitlePage 
                    color='#92330f'
                    text='Sponsoring'
                />}
                {!hasPhone && <Background src={mom2} zIndex='1' top='0' left={hasTablet ? '-200px' : '0'} height='100%' />}
                <Image src={photo} width={hasPhone ? '80vw' : hasTablet ? '30vw' : '20vw'} height={hasPhone ? '50vw' : (imageHeight || '100%')} objectFit='cover' style={{ zIndex: '2', objectPosition: hasTablet && '70% 50%'}} />
                <div style={{ display: 'flex', flexDirection: 'column', width: hasPhone ? '85vw' : hasTablet ? '400px' : '550px', gap: '0.5vw' }} ref={childSponsoringRef}>
                    <Typography type='h2' style={{ color: '#042D34', fontSize: hasPhone ? '2.5em' : '3em', margin: hasPhone ? '0 0 5vw 0' : hasTablet ? '0 0 3vw 0' : '0 0 1.5vw 0' }}>Sponsoring</Typography>
                    <Typography>
                        Goed Aangelegd steunt MOM structureel. Bij elke workshopsreeks die start, ontvangt een Keniaanse vrouw:
                    </Typography>
                    <List>
                        <ListItem>Vervoer naar het ziekenhuis tijdens de bevalling;</ListItem>
                        <ListItem>Kraampakket, inclusief een klamboe tegen Malaria;</ListItem>
                        <ListItem>Eén jaar nazorg met groeicontroles en vaccinaties;</ListItem>
                        <ListItem>Maandelijkse focusgroepen;</ListItem>
                        <ListItem>Zaden voor een groentetuin.</ListItem>
                    </List>
                    <Typography style={{ margin: hasPhone ? '5vw 0 0 0' : hasTablet ? '2vw 0 0 0' : '1vw 0 0 0'}}>Maak jij het verschil voor een Keniaanse moeder en baby? Dit doe je door simpelweg deel te nemen aan de zorgverlening van Goed Aangelegd! Klik snel door naar de groep waar jij bij hoort en meld je aan. </Typography>
                    <div style={{ display: 'flex', position: 'relative', zIndex: '2', gap: hasPhone ? '3vw' : '1vw', margin: hasPhone ? '5vw 0 0 0' : hasTablet ? '3vw 0 0 0' : '1vw 0 0 0', flexWrap: 'wrap' }}>
                        <Button 
                            to='/ouders'
                            text='Ouders'
                            width={hasPhone ? '35vw' : '8vw'}
                            buttonstyle='secondary'
                        />
                        <Button 
                            to='/lactatiekundigen'
                            text='Lactatiekundigen'
                            width={hasPhone ? '35vw' : '8vw'}
                            buttonstyle='secondary'
                        />
                        <Button 
                            to='/verloskundigen'
                            text='Verloskundigen'
                            width={hasPhone ? '35vw' : '8vw'}
                            buttonstyle='secondary'
                        />
                    </div>
                </div>
            </Sponsoring>
        </Container>
    )
}