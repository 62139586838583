import styled from "styled-components"

export const StyledSticker = styled.img`
    position: ${props => props.position || 'absolute'};
    right: ${props => props.right || '10px'};
    bottom: ${props => props.bottom || '10px'};
    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};

    width: ${props => props.width || '7vw'};
    z-index: 7;

    :hover {
        cursor: ${props => props.hover ? 'pointer' : 'default'};
    }

    @media screen and (max-width: 766px) {
        width: 35vw;
        top: auto;
        bottom: 1vw;
        right: 1vw;
    }
`