import React from 'react';
import { Link as DomLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Link(props) {
    if (props.hashLink) {
        return (
            <HashLink smooth to={props.to} style={{ textDecoration: 'none', color: 'inherit', cursor: props.disabled ? 'default' : 'pointer' }}>
                {props.children}
            </HashLink>
        )
    }

    return (
        <DomLink to={props.to} style={{ textDecoration: 'none', color: 'inherit', cursor: props.disabled ? 'default' : 'pointer', ...props.style }}>
            {props.children}
        </DomLink>
    )
}