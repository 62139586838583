import React from 'react';
import { Button, Typography } from '../../components';
import { Circle, Main } from './styled';
import { GeneralContext } from '../../App';

export default function NotFound(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);
    
    return (
        <Main>
            <Circle>
                <Typography type='h2' style={{ fontSize: hasPhone ? '1.6em' : hasTablet ? '3vw' : '2vw' }}>Oeps! <br/> Deze pagina lijkt <br/> niet te bestaan</Typography>
            </Circle>
            <Button 
                to='/'
                text='Ga terug naar homepage'
            />
        </Main>
    );
}