import styled from 'styled-components';

export const StyledSearch = styled.input`
    font-family: Gayathri;
    color: ${props => props.disabled ? props.customStyle.color : 'black'};
    font-size: 1em;

    border: none;

    padding: 0.5vw 0.3vw 0.3vw 0.3vw;
    margin: 0.5vw 0;
    width: 15vw;

    opacity: ${props => props.disabled ? '0.5' : '1'};

    ::placeholder {
        text-align: center;
        text-transform: uppercase;
        color: #114d55;
    }

    &:focus {
        outline: none;
        border-color: black;
        border-width: 3px;
        color: var(--bravery);
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        width: 50vw;
        padding: 1.5vw 1vw 1vw 1vw;
        margin: 2vw 0;
        font-size: 0.8em;
    }
`;