import styled from 'styled-components';

export const Container = styled.div`

`;

export const Nieuwsbrief = styled.div`
    cursor: pointer;
    width: 15vw;

    :hover {
        font-weight: 600;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 45vw;
    }
`;

export const GoedAangelegd = styled.div`
    background-color: #fdfcfa;
    overflow: hidden;
    position: relative;

    padding: 5vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }
`;

export const Nieuwsbrieven = styled.div`
    background-color: #fdfcfa;

    padding: 5vw 0;
    gap: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        gap: 7vw;
        width: 100%;
    }
`;

export const Oprichtster = styled.div`
    position: relative;
    background-color: #f7f3ec;
    padding: 5vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7vw;
    
    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
`;

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};
    transform: ${props => props.flip && 'scaleX(-1)'};
`;