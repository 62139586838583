import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TemporaryBanner = styled.div`
    font-size: 0.8em;
    padding: 0.5vw;
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 1vw 2vw;
        text-align: center;
    }
`;

export const Content = styled.div`
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    align-items: center;
    position: relative;

    padding: ${props => props.isTop? '15px' : '10px'};

    background-color: var(--believe);

    transition: all 0.5s ease 0s;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        height: 3vh;
        justify-content: flex-start;
        // padding: 1vh;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        height: 3vh;
        padding: 1vh;
    }
`;

export const StickyDiv = styled.div`
    position: ${props => props.isTop ? 'static' : 'fixed'};
    top: ${props => props.isTop ? 'auto' : '0'};
    z-index: 1000;
    width: 100%;
    overflow: hidden;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        position: ${props => props.isTop ? 'static' : 'fixed'};
        top: auto;
    }
`;

export const LogoBar = styled(Link)`
    display: flex;
    width: min-content;
    justify-content: center;
    align-items: top;
    height: inherit;
`;

export const Logo = styled.img`
    height: 8vh;
`;

export const SmallLogo = styled.img`
    height: 2.5vw;

    @media screen and (max-width: 1023px) {
        height: 100%;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        height: 100%;
    }

    
    
`;

export const ButtonFloat = styled.div`
    display: flex;
    gap: 15px;

    position: absolute;
    z-index: 1;
    right: 15px;
    top: 15px;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        top: 25%;
        right: 2vw;
        gap: 3vw;
    }
`;

export const ButtonBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    // gap: 15px;
    position: relative;

    @media screen and (max-width: 1023px) {
        height: inherit;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        height: inherit;
    }
`;

export const ResponsiveMenu = styled.div`
    height: calc(100vh - 50px);
    background-color: #F6F3EC;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;

    transform: ${props => props.open ? 'translateX(0)' : 'translateX(-120%)'};
    transition: all 0.5s ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10vw;
    padding: 20vh 0 5vw 0;

    @media screen and (max-width: 767px) {
        gap: 15vw;
        padding: 15vh 0 5vw 0;
    }
`;

export const BoldLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    font-weight: 600;
`