import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Gayathri, Arial',
  },
  palette: {
    primary: {
      main: '#92330f',
    },
    secondary: {
      main: '#93b6ae',
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
);