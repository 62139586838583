import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { Container, SingleArrow } from './styled';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import './Sample.css';
import { mod } from '../../helpers/others';
import { GeneralContext } from '../../App';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
  };

export default function PDFViewer(props) {
    const { file } = props;

    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ numPages, setNumPages ] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess(pdf) {
        setNumPages(pdf.numPages);

        // react pdf indexing starts at 1
        setPageNumber(1);
    }

    const changePage = (newPageNumber) => {
        setPageNumber(mod(newPageNumber-1, numPages)+1);
    }

    if (file) {
        return (
            <Container>
                {!hasPhone && <SingleArrow
                    onClick={() => changePage(pageNumber-1)}
                >
                    {'<'}
                </SingleArrow>}
                <div className="Example__container__document">
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options} className='Document'>
                        <Page pageNumber={pageNumber} className='Page' noData='Geen pagina gevonden' />
                        {/* <Page pageNumber={mod(pageNumber, numPages)+1}  /> */}
                    </Document>
                </div>
                {!hasPhone && <SingleArrow
                    onClick={() => changePage(pageNumber+1)}
                >
                    {'>'}
                </SingleArrow>}
            </Container>
        )
    }
    
    return <div>Geen bestand gevonden</div>
}