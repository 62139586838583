import styled from 'styled-components';

export const Borstvoedingszorg = styled.div` 
    background-color: #f7f3ec;
    padding: 5vw 2vw;
    position: relative;
    z-index: auto;

    display: flex;
    gap: 7.5vw;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        justify-content: center;
        flex-wrap: wrap;
        padding: 15vw 0;
    }
`;

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.widthStyle || 'default'};
    height: ${props => props.heightStyle || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};

    object-fit: cover;
`;  

export const Inleiding = styled.div`
    background-color: white;
    padding: 5vw 0;
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 15vw 0;
    }

    @media screen and (max-width: 1023px) {
        padding: 10vw 0;
    } 
`;

export const Waarom = styled.div`
    background-color: #f7f3ec;
    position: relative;
    padding: 5vw 0vw;
    width: 100%;
    gap: 5vw;
    z-index: 3;

    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
        padding: 15vw 5vw;
        // text-align: center;
    }

    @media screen and (max-width: 1023px) {
        flex-wrap: wrap;
        padding: 10vw 0;
        gap: 7vw;
    } 
`;

export const Sponsoring = styled.div`
    background-color: white;
    width: 100%;
    position: relative;
    
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    gap: 10vw;

    @media screen and (max-width: 766px) {
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 1023px) {
        gap: 7vw;
    } 
`;

export const BlogEnPodcast = styled.div`
    width: 100%;
    background-color: #f6efe7;
    padding: 5vw 0vw;
    text-align: center;
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }    
`;

export const Ervaringen = styled.div`
    width: 100%;
    background-color: white;

    padding: 5vw 0vw;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    text-align: center;
`

export const Benieuwd = styled.div`
    background-color: white;
    width: 100%;
    padding: 5vw 0vw;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    overflow: hidden;
`;

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: #f6efe7;
    width: 80px;
    height: 80px;
`;