import React from 'react';
import { GeneralContext } from '../../App';
import { Typography, Button } from '../../components';

export default function Gratis(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: 'white', flexDirection: 'column', alignItems: 'center', padding: '5vw 0', height: '100%', minHeight: hasPhone || hasTablet ? '100vh' : '45vh'}}>
            <Typography type='h2' style={{ color: '#BB6848', margin: hasPhone ? '20vh 0 10vw 0' : hasTablet ? '10vh 0 10vw 0' : '5vw 0 5vw 0', maxWidth: '80vw' }}>Wil jij een praktische samenvatting van alle afleveringen?</Typography>
            <Typography type='caps' style={{ color: '#B2522D', margin: hasPhone ? '0 0 10vw 0' : hasTablet ? '0 0 5vw 0' : '0 0 2vw 0', maxWidth: '80vw' }}>DOWNLOAD HIER GRATIS <br/>DE SUCCESVOLLE BORSTVOEDINGSCHECKLIST</Typography>
            <Button 
                onClick={() => window.open('https://mailchi.mp/f555bfdde75b/gratis-checklist-succesvolle-borstvoeding', '_blank')}
                text='YES, DEZE WIL IK!'
            />
        </div>
    )
}