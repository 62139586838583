import React from "react";
import { Text, Container, Check, Circle, Box } from './styled';
import VinkjeIcon from "../../Icon/VinkjeIcon";
import { GeneralContext } from "../../../App";

export default function Checkbox(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    return (
        <Container text={props.text} style={props.style} >
            <Check onClick={props.onClick} clickable={props.onClick}>
                <Circle circleWidth={props.circleWidth} />
                {props.isChecked && <Box boxLeft={props.boxLeft} boxWidth={props.boxWidth}>
                    <VinkjeIcon vinkjeWidth={props.vinkjeWidth} color={props.fill || '#BE6842'} />
                </Box>}
            </Check>
            <Text textSize={props.textSize}>{props.text}</Text>
        </Container>
    )
}