import React, { useState } from 'react';
import { TextField, Modal, Button } from '../../components';

import { isEmail, isIBCLC } from '../../helpers/form';

export default function BrochureModal(props) {
    const { openModal, setOpenModal, category } = props;

    const [ emailValue, setEmailValue ] = useState('');
    const [ ibclcValue, setIbclcValue ] = useState('');
    const [ errors, setErrors ] = useState({});

    const sendBrochureEmail = (event) => {
        event.preventDefault();

        const errors = {};

        if (!isEmail(emailValue)) {
            errors.email = 'Vul een geldig e-mailadres in'
        }

        if (!isIBCLC(ibclcValue) && category === 'lactatiekundige') {
            errors.ibclc = 'Vul een geldige IBCLC-code in'
        }

        setErrors(errors);

        // no errors
        if (!Object.keys(errors).length) {
            setErrors({errors})

            // todo: send email with brochure attached?
            alert([emailValue, ibclcValue, category]);
        }
    }

    return (
        <Modal
            open={openModal}
            onSubmit={sendBrochureEmail}
            onClose={() => { setOpenModal(false); setErrors({}) }}
            title={props.title}
        >
            <TextField 
                placeholder='E-mailadres'
                email
                value={emailValue}
                onChange={(event) => setEmailValue(event.target.value)}
                customStyle='secondary'
                errors={errors.email}
                required
            />
            {category === 'lactatiekundige' && <TextField 
                placeholder='IBCLC-code'
                ibclc
                value={ibclcValue}
                onChange={(event) => setIbclcValue(event.target.value)}
                customStyle='secondary'
                required={category === 'lactatiekundige'}
                errors={errors.ibclc}
            />}
            <Button type='submit' text={props.buttonText} style={{ margin: '1vw 0 0.5vw 0' }} buttonstyle='secondary'/>
        </Modal>
    )
}