import React, { useState } from "react";
import { StyledSearch } from "./styled";

export default function SearchField(props) {
    const [ value, setValue ] = useState('');

    const { items, property, displayNr, parentFunc, placeholder } = props;

    const  onChange = (value) => {
        let newValue = value.toLowerCase();
        setValue(newValue);
        let newAnswers = updateItems(newValue);
        parentFunc(newAnswers);
    }

    const updateItems = (newValue) => {
        let newItems = items;
        if (newValue.length > 0) {
            newItems = []
            for (let i=0; i < items.length; i++) {
                let item = items[i];
                let itemStr = item;
                if (property) {
                    itemStr = item[property];
                }
                itemStr = itemStr.toLowerCase();

                if (itemStr.indexOf(newValue) >= 0) {
                    newItems.push(item);
                }
            }

            if (displayNr) {
                newItems = newItems.slice(0, displayNr);
            }
        }
        return newItems;
    }

    return (
        <StyledSearch
            disabled={props.disabled}
            value={value} 
            placeholder={placeholder}
            onChange={(event) => onChange(event.target.value) }
        />
    )
}